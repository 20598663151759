import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import toast, { Toaster } from "react-hot-toast";
import useDocumentTitle from "../../useDocumentTitle";
import { CountryDropdown } from "react-country-region-selector";
import { useHistory } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import axios from "axios";

import { Link, useLocation } from "react-router-dom";
import useLocalePrefix from "../utils/useLocalePrefix";

const Modal_referrals = ({ setIsOpen }) => {

  useDocumentTitle("Invu POS | Referidos ");
  // 
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const [language, setLenguage] = useState("es");

//Campos 
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [nameReferredStore, setNameReferredStore] = useState("");
  const [country, setCountry] = useState("");
  const [webUrl, setWebUrl] = useState("");
  const [facebookCompanyName, setFacebookCompanyName] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [fullnameReferrer, setFullnameReferrer] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  // const leadStatus = "Prospecto Referido"

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const history = useHistory();

  const location = useLocation();
  const prefix = useLocalePrefix();
  const [countryRender, setCountryRender] = useState('');
  const [loading, setLoading] = useState(true); 

  const [geoInfo, setGeoInfo] = useState({
    ip: '',
    countryName: '',
    countryCode: '',
    city: '',
    timezone: ''
  });

  const getGeoInfo = () => { 
    axios
      .get('https://ipapi.co/json/')
      .then((response) => {
        const data = response.data;
        setGeoInfo({
          ip: data.ip,
          countryName: data.country_name,
          countryCode: data.country_calling_code,
          city: data.city,
          timezone: data.timezone
        });
        setCountryRender(data.country_name);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  
  useEffect(() => {
    getGeoInfo();
  }, []);

  if (loading) {
    return <div></div>;
  }

  function generateUUID() {
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = (Math.random() * 16) | 0,
          v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  var hutkValue = generateUUID();

  const handleSubmit = (evt) => {
    evt.preventDefault();
    var xhr = new XMLHttpRequest();

    var url =
    "https://api.hsforms.com/submissions/v3/integration/submit/3797225/288d12b9-b8c7-47a1-b826-98e42912f9b2";

    var data = {
      fields: [
        {
          name: "firstname",
          value: firstName,
        },
        {
          name: "lastname",
          value: lastName,
        },
        {
          name: "email",
          value: email,
        },
        {
          name: "mobilephone",
          value: phone,
        },
        {
          name: "name",
          value: nameReferredStore,
          objectTypeId: "COMPANY",
        },
        {
          name: "country",
          value: country,
          objectTypeId: "COMPANY",
        },
        {
          name: "website",
          value: webUrl,
          objectTypeId: "COMPANY",
        },
        {
          name: "facebook_company_page",
          value: facebookCompanyName,
          objectTypeId: "COMPANY",
        },
        {
          name: "mipropiedadtestname",
          value: whatsappNumber,
          objectTypeId: "COMPANY",
        },
        {
          name: "cual_es_su_nivel_de_experiencia_en_la_realizacion_de_entrenamientos_para_la_implementacion_de_soluc",
          value: fullnameReferrer,
          objectTypeId: "COMPANY",
        },
      ],
      context: {
        hutk: hutkValue,
        ipAddress: geoInfo.ip,
        pageUri: "www.invupos.com",
        pageName: "formulario de referido invu pos"
      },
    };
    console.log(data);
    var final_data = JSON.stringify(data);

    xhr.open("POST", url);
    // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
    xhr.setRequestHeader("Content-Type", "application/json");

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        const responseData = JSON.parse(xhr.responseText);
        const registrationId = responseData.inlineMessage;
        history.push("/thanks-for-sending-message");
        // alert(xhr.responseText);
      } else if (xhr.readyState === 4 && xhr.status === 403) {
        alert(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.
      } else if (xhr.readyState === 4 && xhr.status === 404) {
        // alert(xhr.responseText);
        toast.custom((t) => (
          <div
            className={`${
              t.visible ? "animate-enter" : "animate-leave"
            } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
          >
            <div className="flex-1 w-0 p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0 pt-0.5">
                  <img
                    className="h-10 w-10 rounded-full"
                    src="https://res.cloudinary.com/dslkvd23s/image/upload/v1651769707/Branding%20Invu%20/Logo%20Apps/Layer_2_pxylmq.png"
                    alt=""
                  />
                </div>
                <div className="ml-3 flex-1">
                  <p className="text-sm font-medium text-gray-900">Invu POS</p>
                  <p className="mt-1 text-sm text-gray-500">
                    <span className="font-semibold text-red-600">Error</span>{" "}
                    Bad request ❌
                  </p>
                </div>
              </div>
            </div>
            <div className="flex border-l border-gray-200">
              <button
                onClick={() => toast.dismiss(t.id)}
                className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                Close
              </button>
            </div>
          </div>
        ));
      }
    };

    xhr.send(final_data);
    clearForm();
  };

  const clearForm = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setNameReferredStore("");
    setCountry("Select Country");
    setWebUrl("");
    setFacebookCompanyName("");
    setWhatsappNumber("");
    setFullnameReferrer("");
    setIsChecked(false); // Limpia el checkbox
  };

  return (
    <>
      <div class="flex justify-center flex overflow-x-hidden overflow-y-auto z-50 inset-0 fixed h-screen items-center antialiased bg-gray-500 bg-opacity-75 transition-opacity ">
        <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 max-w-2xl mx-auto rounded-lg border border-gray-300 shadow-xl">
          <form onSubmit={handleSubmit}>
            <div class="flex flex-row justify-between p-6 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg">
              <p class="font-semibold text-gray-800">{t("referralmodaltitle")}</p>
              <button onClick={() => setIsOpen(false)}>
                <svg
                  class="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
            <div class="flex flex-col px-6 py-5  bg-gray-50">
              <div className="flex">
            <a href="">
                {countryRender === 'Mexico' && (
                  <img
                    className="h-12 w-auto"
                    src="https://img.invupos.com/invu%20mexico/Logo%20invu%20mx.svg"
                    alt=""
                  />
                )}
                {countryRender === 'Venezuela' && (
                  <img
                    className="h-12 w-auto"
                    src="https://img.invupos.com/invu%20venezuela/Logo%20invu%20vn.svg"
                    alt=""
                  />
                )}
                {(countryRender !== 'Mexico' && countryRender !== 'Venezuela') && (
                  <img
                    className="h-12 w-auto"
                    src="https://res.cloudinary.com/dslkvd23s/image/upload/v1648001932/Branding%20Invu%20/Logo%20Invu/logo-INVU-POS-rgb_nrg6ry.png"
                    alt=""
                  />
                )}
              </a>
              <div className="ml-6 content-center">
              <span class="bg-green-100 text-green-800 text-sm font-medium px-3 py-1 rounded-full">{t("referralsspanform")}</span>
              </div>
              </div>
              {/* <hr className="mb-5" /> */}

              <div class="flex flex-col mt-6 sm:flex-row items-center mb-3 sm:space-x-5">
                <div class="w-full sm:w-1/2">
                  <div>
                    <label class="sr-only" for="firstname">
                      First name
                    </label>
                    <input
                      class="w-full p-4 bg-white border border-gray-200 rounded shadow-sm"
                      placeholder={t("referralmodalfirstname")}
                      required
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                </div>
                <div class="w-full sm:w-1/2 mt-2 sm:mt-0">
                  <div>
                    <label class="sr-only" for="firstname">
                      Last name
                    </label>
                    <input
                      class="w-full p-4 bg-white border border-gray-200 rounded shadow-sm"
                      placeholder={t("referralmodallastname")}
                      required
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div class="flex flex-col sm:flex-row items-center mb-3 sm:space-x-5">
                <div class="w-full sm:w-1/2">
                  <div>
                    <label class="sr-only" for="firstname">
                      Email
                    </label>
                    <input
                      class="w-full p-4 bg-white border border-gray-200 rounded shadow-sm"
                      placeholder={t("referralmodalemail")}
                      required
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div class="w-full sm:w-1/2 mt-2 sm:mt-0">
                  <div>
                    <label class="sr-only" for="firstname">
                      Phone
                    </label>
                    <PhoneInput
                      className="w-full p-4 bg-white border border-gray-200 rounded shadow-sm"
                      placeholder={t("referralmodalmobilephone")}
                      defaultCountry="PA"
                      required
                      value={phone}
                      onChange={(value) => setPhone(value)}
                    />
                  </div>
                </div>
              </div>

              <div class="flex flex-col sm:flex-row items-center mb-3 sm:space-x-5">
                <div class="w-full sm:w-1/2">
                  <div>
                    <label class="sr-only" for="firstname">
                      Nombre del comercio referido
                    </label>
                    <input
                      class="w-full p-4 bg-white border border-gray-200 rounded shadow-sm"
                      placeholder={t("referralmodalname")}
                      required
                      type="text"
                      value={nameReferredStore}
                      onChange={(e) => setNameReferredStore(e.target.value)}
                    />
                  </div>
                </div>
                <div class="w-full sm:w-1/2 mt-2 sm:mt-0">
                  <div>
                      <input
                        class="w-full p-4 bg-white border border-gray-200 rounded shadow-sm"
                        placeholder={t("referralmodalfullnamereferral")}
                        required
                        type="text"
                        value={fullnameReferrer}
                        onChange={(e) => setFullnameReferrer(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <div>
                    <CountryDropdown
                      type="text"
                      priorityOptions={["PA", "CO", "CR", "VE", "MX"]}
                      blacklist={["PS"]}
                      defaultOptionLabel={t("referralmodalcountry")}
                      value={country}
                      class="w-full p-4 bg-white border border-gray-200 rounded shadow-sm text-gray-900"
                      onChange={(e) => setCountry(e)}
                  />
                </div>
              </div>

              <div className="mb-3">
                <input
                    class="w-full p-4 bg-white border border-gray-200 rounded shadow-sm"
                    placeholder={t("referralmodalweburl")}
                    required
                    type="text"
                    value={webUrl}
                    onChange={(e) => setWebUrl(e.target.value)}
                />
              </div>

              {/* <div className="mb-3">
                <input
                    class="w-full p-4 bg-white border border-gray-200 rounded shadow-sm"
                    placeholder={t("referralmodalfacebook")}
                    required
                    type="text"
                    value={facebookCompanyName}
                    onChange={(e) => setFacebookCompanyName(e.target.value)}
                />
              </div> */}

              <div className="mb-3">
                  <div>
                    <label class="sr-only" for="firstname">
                      WhatsApp
                    </label>
                    <PhoneInput
                      className="w-full p-4 bg-white border border-gray-200 rounded shadow-sm"
                      placeholder={t("referralmodalwhatsapp")}
                      defaultCountry="PA"
                      required
                      value={whatsappNumber}
                      onChange={(value) => setWhatsappNumber(value)}
                    />
                  </div>
              </div>

              <div class="mt-6 mb-2 text-left text-gray-600 dark:text-gray-400 flex items-center justify-between">
                <div class="flex items-center">
                  <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} placeholder="Privacy Policy" required class="w-5 h-5  text-blue-600 border-gray-200 rounded focus:ring-blue-500"/>
                  <label class="block ml-2 text-[14px] text-neutral-600"> {t("privacypolicytext1")} {" "}
                  <Link
                     className={`underline font-bold ${countryRender === 'Mexico' ? 'text-green-700' : countryRender === 'Venezuela' ? 'text-[#001F7E]' : 'text-indigo-600'}`}
                    to={`${[prefix]}` + "/politicas-de-privacidad"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {t("privacypolicytexthref2")}
                  </Link>
                    {" "}
                      {t("privacypolicytext3")}</label>
                </div>
                
              </div>
            </div>

            <div class="flex flex-row items-center justify-between p-5 bg-white border-t border-gray-200 rounded-bl-lg rounded-br-lg">
              <p
                onClick={() => setIsOpen(false)}
                class="font-semibold text-gray-600 cursor-pointer"
              >
                {t("modalformbuttoncancel")}
              </p>
              <button
                type="submit"
                value="Submit"
                className={`inline-flex items-center px-8 py-3 ${countryRender === 'Mexico' ? 'bg-green-700 text-white' : countryRender === 'Venezuela' ? 'text-[#000000] bg-[#FED850]' : 'bg-indigo-600 text-white'} border rounded hover:opacity-90`}
              >
                <span class="text-sm font-medium">{t("buttonnewmodal")}</span>
              </button>
            </div>
          </form>
          <Toaster position="bottom-center" reverseOrder={false} />
        </div>
      </div>
    </>
  )
}

export default Modal_referrals