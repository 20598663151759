import React from 'react'
import { Helmet } from "react-helmet";
import useDocumentTitle from "../../useDocumentTitle";
import { useTranslation } from "react-i18next";

const Article_abastur_mexico = () => {
    useDocumentTitle(
        "Anunciamos nuestra participación en ABASTUR México 2024 | Invu POS"  
      );  
  return (
    <>
    <Helmet>
        <meta
        name="description"
        content="Estamos emocionados de anunciar que participaremos en ABASTUR México 2024, el evento más grande y relevante para la industria de la hospitalidad en América Latina. Este año marca un hito importante para nosotros, ya que no solo estaremos presentes en la feria, sino que también celebramos nuestra llegada oficial al mercado mexicano."
        />
        <meta name="keywords" content="Anunciamos Nuestra Participación en ABASTUR México 2024 y Nuestra Llegada a México" />
   </Helmet>
   <article class="px-4 py-24 mx-auto max-w-7xl -mt-14" itemid="#" itemscope itemtype="http://schema.org/BlogPosting">
        <div class="w-full mx-auto mb-12 text-left md:w-3/4 lg:w-1/2">
            <div className="flex space-x-3 mb-2">
            <p class="mt-6 mb-4 py-2 px-5 rounded-full text-xs font-semibold tracking-widest uppercase text-[#6D28D9] bg-[#f8f3ff]">Nota de prensa</p>
            <p class="mt-6 mb-4 py-2 px-5 rounded-full text-xs font-semibold tracking-widest uppercase text-[#6D28D9] bg-[#f8f3ff]">Artículo</p>
            </div>
            <h1 class="mb-6 text-3xl font-bold leading-tight text-gray-900 md:text-4xl" itemprop="headline">
            Anunciamos nuestra participación en ABASTUR México 2024
            </h1>
            <p class="text-base text-gray-500 italic">Fecha de publicación — <span className="font-semibold">August 28, 2024</span></p>
            <div class="pb-6 mb-6 border-b border-gray-200"></div>
            <p className="text-gray-700 leading-7 mb-6">
            Estamos emocionados de anunciar que participaremos en <span className='font-semibold'>ABASTUR México 2024,</span> el evento más grande y relevante para la industria de la hospitalidad en América Latina. Este año marca un hito importante para nosotros, ya que no solo estaremos presentes en la feria, sino que también celebramos nuestra llegada oficial al mercado mexicano.
            </p>
            <div class="order-2 h-72 md:order-1 md:h-full rounded-xl">
            <img
                src="https://img.invupos.com/webinvu/Blog%20invu/anunciamos-nuestra-participacion-en-abastur-mexico-2024.jpg"
                class="w-full h-full bg-center object-cover rounded-xl"
                alt=""
            />
            </div>
   
            <a class="flex items-center text-gray-700 mt-6" href="#">
            <div class="gradient-div"></div>
            <div class="ml-2">
                <p class="text-medium font-semibold text-gray-800">De: Invu POS</p>
            </div>
            </a>
            <div class="pb-6 mb-6 border-b border-gray-200"></div>
   
        </div>
   
        <div class="w-full mx-auto prose md:w-3/4 lg:w-1/2">
   
   
           <p className="mt-4 text-gray-700 leading-7">
           En <span className='font-semibold'>Invu POS</span>, nuestro objetivo ha sido siempre transformar la manera en que los restaurantes y negocios de hospitalidad operan, ayudándoles a ser más eficientes y a brindar experiencias excepcionales a sus clientes. 
           </p>

           <p className="mt-4 text-gray-700 leading-7">
           Nuestra tecnología avanzada combina un sistema de punto de venta intuitivo con herramientas de marketing inteligentes y soluciones de fidelización de clientes que permiten optimizar operaciones y potenciar el crecimiento de los negocios. Con esta visión, llegamos a México listos para apoyar a emprendedores, restauranteros y hoteleros en sus retos diarios.
           </p>

           <p className="mt-4 text-gray-700 leading-7">
           <span className='font-semibold'>ABASTUR 2024 es el lugar perfecto para dar a conocer nuestra oferta.</span> Esta feria no solo reúne a los líderes de la industria, sino que es un punto de encuentro donde se comparten ideas, se descubren nuevas tendencias y se generan oportunidades para colaborar y crecer. 
           </p>

           <p className="mt-4 text-gray-700 leading-7">
           Para nosotros, estar aquí significa acercarnos a las necesidades del mercado mexicano, entender sus dinámicas y mostrar cómo nuestras soluciones tecnológicas pueden adaptarse para mejorar los negocios de hospitalidad en la región.
           </p>

           <p className="mt-4 text-gray-700 leading-7">
           En nuestro stand, los asistentes podrán explorar de primera mano cómo funciona Invu POS, desde nuestra interfaz intuitiva hasta nuestras integraciones con programas de lealtad y wallets digitales. Creemos firmemente que la tecnología debe ser un aliado que potencie el éxito de cada negocio, y estamos convencidos de que, con nuestras herramientas, los restaurantes y hoteles de México podrán alcanzar nuevas alturas.
           </p>


           <img src="https://images.pexels.com/photos/1573471/pexels-photo-1573471.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" class="object-cover  w-full h-72 object-top rounded-lg mt-8" alt="" />

           <p className="mt-10 text-gray-700 leading-7">
           <span className='font-semibold'>ABASTUR es solo el comienzo de nuestra expansión en este vibrante mercado.</span> Estamos comprometidos a ser un socio clave para los negocios mexicanos, brindando un soporte continuo y una plataforma que evoluciona conforme a las necesidades del sector. 
           </p>

           <p className="mt-4 text-gray-700 leading-7">
           Nuestro equipo estará disponible durante toda la feria para responder preguntas, ofrecer demostraciones y mostrar cómo Invu POS puede ayudar a que cada negocio de hospitalidad no solo sobreviva, sino prospere en un entorno tan competitivo.
           </p>

           <p className="mt-4 text-gray-700 leading-7">
           Esperamos verte en ABASTUR 2024. ¡Ven a conocernos y descubre cómo podemos ayudarte a llevar tu negocio al siguiente nivel!
           </p>

            <div class="pb-6 mb-6 border-b border-gray-200"></div>
            <div class="flex items-center mb-6 space-x-2">
            <p class="text-base text-gray-500">
            August 28, 2024 — Written by:{" "}
                <span className="underline font-regular">Andres Amaya</span> 
            </p>
            </div>
        </div>
    </article>
    </>
  )
}

export default Article_abastur_mexico