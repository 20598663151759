import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import CTA_home from '../Home/CTA_home';
import Industries_carousel_pa from './Industries_carousel_pa';
import Modal_started from "../Home/Modal_started";
import FAQ_model_one from './FAQ_model_one';
import Main_tabs_demo from "../modules/tabsdemo/Main_tabs_demo";
import FAQ_business_types from './FAQ_business_types';



const DemoFigma = () => {
  const [height, setHeight] = useState('1000px');
  const isMobile = window.innerWidth <= 768; 

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      if (width <= 640) {
        setHeight('350px'); // Móviles pequeños
      } else if (width <= 768) {
        setHeight('600px'); // Tabletas en orientación vertical
      } else if (width <= 1024) {
        setHeight('650px'); // Tabletas en orientación horizontal y laptops pequeñas
      } else if (width <= 1280) {
        setHeight('800px'); // Laptops medianas y monitores pequeños
      } else {
        setHeight('885px'); // Monitores grandes y resoluciones mayores
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial value

    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  // <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2Fm2SxDJKgzkr9K5UCoTn4Ya%2FPrototype-system-Invu%3Fnode-id%3D533-309%26node-type%3DCANVAS%26t%3DtOnevvtQivOeGHGX-1%26scaling%3Dscale-down%26content-scaling%3Dfixed%26page-id%3D533%253A308" allowfullscreen></iframe>


  return (
    <>

    {isOpen && <Modal_started setIsOpen={setIsOpen} />}

    {/* <section className="bg-[#121212]">
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-32">
      <div className="grid gap-16 lg:grid-cols-2">
        <div className="lg:pr-10 content-center">
          <h1 class="font-bold xl:text-[50px] lg:text-5xl md:text-5xl text-4xl text-white">
          Mas que un sistema POS, somos tus aliado para el éxito de tu negocio
          </h1>
          <div class="mt-7 grid gap-3 w-full sm:inline-flex">
            <button 
            onClick={() => setIsOpen(true)}
            class="py-4 px-5 inline-flex justify-center text-lg items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent bg-indigo-600 text-white hover:bg-indigo-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="#">
              {t("posbutton1")}
            </button>
          </div>
          <div class="mt-6 lg:mt-12 mb-6">
                    <span class="text-xs font-semibold text-gray-500 uppercase tracking-widest">{t("availableinhero")}</span>
                    <div class="mt-4 flex gap-x-8">
                      <a href="https://bit.ly/4cXCHQm" target="_blank">
                        <svg class="icon text-gray-500 hover:text-gray-800 transition duration-300 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                            <path fill="currentColor" d="m3.001 5.479l7.377-1.016v7.127H3zm0 13.042l7.377 1.017v-7.04H3zm8.188 1.125L21.001 21v-8.502h-9.812zm0-15.292v7.236h9.812V3z"/>
                        </svg>
                      </a>
                      <a href="https://bit.ly/3W8xWN4" target="_blank">
                        <svg class="icon text-gray-500 hover:text-gray-800 transition duration-300 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 315">
                            <path fill="currentColor" d="M213.803 167.03c.442 47.58 41.74 63.413 42.197 63.615c-.35 1.116-6.599 22.563-21.757 44.716c-13.104 19.153-26.705 38.235-48.13 38.63c-21.05.388-27.82-12.483-51.888-12.483c-24.061 0-31.582 12.088-51.51 12.871c-20.68.783-36.428-20.71-49.64-39.793c-27-39.033-47.633-110.3-19.928-158.406c13.763-23.89 38.36-39.017 65.056-39.405c20.307-.387 39.475 13.662 51.889 13.662c12.406 0 35.699-16.895 60.186-14.414c10.25.427 39.026 4.14 57.503 31.186c-1.49.923-34.335 20.044-33.978 59.822M174.24 50.199c10.98-13.29 18.369-31.79 16.353-50.199c-15.826.636-34.962 10.546-46.314 23.828c-10.173 11.763-19.082 30.589-16.678 48.633c17.64 1.365 35.66-8.964 46.64-22.262"/>
                        </svg>
                      </a>
                      <a href="https://bit.ly/4cJt3Be" target="_blank">
                          <svg class="icon text-gray-500 hover:text-gray-800 transition duration-300 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 20 20">
                              <path fill="currentColor" fill-rule="evenodd" d="M12.735 1.828L13.6.254a.172.172 0 0 0-.3-.164l-.874 1.59a5.987 5.987 0 0 0-2.428-.508c-.869 0-1.69.181-2.424.506L6.698.09a.171.171 0 0 0-.232-.069a.169.169 0 0 0-.069.232l.866 1.572c-1.7.874-2.85 2.537-2.849 4.447l11.166-.001c0-1.91-1.146-3.57-2.845-4.444m-5.278 2.43a.467.467 0 1 1 .001-.934a.467.467 0 0 1 0 .935M15.542 6.7l.002 8.012c0 .481-.262.897-.648 1.127a1.31 1.31 0 0 1-.675.19l-.904.001v2.734a1.237 1.237 0 0 1-1.489 1.21a1.238 1.238 0 0 1-.99-1.209V16.03H9.163v2.735A1.237 1.237 0 0 1 7.925 20a1.239 1.239 0 0 1-1.238-1.235V16.03h-.901c-.521 0-.967-.3-1.182-.736a1.298 1.298 0 0 1-.141-.581l-.002-8.01zM2.74 6.47c.684 0 1.24.553 1.24 1.234v5.17a1.238 1.238 0 0 1-1.922 1.03a1.231 1.231 0 0 1-.557-1.03L1.5 7.703c0-.68.555-1.234 1.24-1.234m14.52-.001c.684 0 1.24.552 1.24 1.234v5.169c0 .683-.555 1.235-1.239 1.235c-.685 0-1.24-.552-1.24-1.235V7.702c0-.682.554-1.235 1.238-1.234M12.54 3.325a.467.467 0 1 1 0 .933a.467.467 0 1 1 0-.933"/>
                          </svg>
                      </a>
                    </div>
                  </div>
        </div>
        
        <div>
          <img
            className="object-cover w-full h-80 rounded-2xl shadow-lg sm:h-[480px]"
            src="https://img.invupos.com/webinvu/Point%20of%20sale%20module/Header%20pos%20.jpg" 
            alt=""
          />
        </div>
      </div>
    </div>
    </section> */}

<section class="relative py-10">
        <img
          class="absolute inset-0 object-[75%] sm:object-[25%] object-cover w-full h-full opacity-85 sm:opacity-100"
          src="https://images.pexels.com/photos/2182981/pexels-photo-2182981.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
          alt=""
        />
        <div className="absolute inset-0 bg-gray-900 bg-opacity-50"></div>

        <div class="hidden sm:block sm:inset-0 sm:absolute sm:bg-gradient-to-r sm:from-pirDarkGray sm:to-transparent"></div>

        <div class="relative max-w-screen-xl  px-4  sm:px-6 lg:px-8 py-32 mx-auto lg:h-4/6 lg:items-center lg:flex">
          <div class="max-w-3xl text-center sm:text-center mx-auto">
            <h1 class="text-3xl text-white font-extrabold sm:text-5xl">
            {t("demomoduletitle")}
            </h1>


   <div class="mt-6 lg:mt-10 mb-6 ">
              <span class="text-xs font-semibold text-gray-100 uppercase tracking-widest">{t("availableinhero")}</span>
              <div class="mt-4 flex gap-x-8 justify-center">

              <a href="https://bit.ly/4cXCHQm" target="_blank">
                        <svg class="icon text-gray-100 hover:text-gray-300 transition duration-300 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                            <path fill="currentColor" d="m3.001 5.479l7.377-1.016v7.127H3zm0 13.042l7.377 1.017v-7.04H3zm8.188 1.125L21.001 21v-8.502h-9.812zm0-15.292v7.236h9.812V3z"/>
                        </svg>
                      </a>
                      <a href="https://bit.ly/3W8xWN4" target="_blank">
                        <svg class="icon text-gray-100 hover:text-gray-300 transition duration-300 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 315">
                            <path fill="currentColor" d="M213.803 167.03c.442 47.58 41.74 63.413 42.197 63.615c-.35 1.116-6.599 22.563-21.757 44.716c-13.104 19.153-26.705 38.235-48.13 38.63c-21.05.388-27.82-12.483-51.888-12.483c-24.061 0-31.582 12.088-51.51 12.871c-20.68.783-36.428-20.71-49.64-39.793c-27-39.033-47.633-110.3-19.928-158.406c13.763-23.89 38.36-39.017 65.056-39.405c20.307-.387 39.475 13.662 51.889 13.662c12.406 0 35.699-16.895 60.186-14.414c10.25.427 39.026 4.14 57.503 31.186c-1.49.923-34.335 20.044-33.978 59.822M174.24 50.199c10.98-13.29 18.369-31.79 16.353-50.199c-15.826.636-34.962 10.546-46.314 23.828c-10.173 11.763-19.082 30.589-16.678 48.633c17.64 1.365 35.66-8.964 46.64-22.262"/>
                        </svg>
                      </a>
                      <a href="https://bit.ly/4cJt3Be" target="_blank">
                          <svg class="icon text-gray-100 hover:text-gray-300 transition duration-300 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 20 20">
                              <path fill="currentColor" fill-rule="evenodd" d="M12.735 1.828L13.6.254a.172.172 0 0 0-.3-.164l-.874 1.59a5.987 5.987 0 0 0-2.428-.508c-.869 0-1.69.181-2.424.506L6.698.09a.171.171 0 0 0-.232-.069a.169.169 0 0 0-.069.232l.866 1.572c-1.7.874-2.85 2.537-2.849 4.447l11.166-.001c0-1.91-1.146-3.57-2.845-4.444m-5.278 2.43a.467.467 0 1 1 .001-.934a.467.467 0 0 1 0 .935M15.542 6.7l.002 8.012c0 .481-.262.897-.648 1.127a1.31 1.31 0 0 1-.675.19l-.904.001v2.734a1.237 1.237 0 0 1-1.489 1.21a1.238 1.238 0 0 1-.99-1.209V16.03H9.163v2.735A1.237 1.237 0 0 1 7.925 20a1.239 1.239 0 0 1-1.238-1.235V16.03h-.901c-.521 0-.967-.3-1.182-.736a1.298 1.298 0 0 1-.141-.581l-.002-8.01zM2.74 6.47c.684 0 1.24.553 1.24 1.234v5.17a1.238 1.238 0 0 1-1.922 1.03a1.231 1.231 0 0 1-.557-1.03L1.5 7.703c0-.68.555-1.234 1.24-1.234m14.52-.001c.684 0 1.24.552 1.24 1.234v5.169c0 .683-.555 1.235-1.239 1.235c-.685 0-1.24-.552-1.24-1.235V7.702c0-.682.554-1.235 1.238-1.234M12.54 3.325a.467.467 0 1 1 0 .933a.467.467 0 1 1 0-.933"/>
                          </svg>
                      </a>

              </div>
            </div>
          

            <div class="flex flex-wrap gap-4 mt-8 text-center items-center align-center flex justify-center">
              <button
                class="block w-full px-8 py-4 text-sm font-medium text-white rounded shadow bg-indigo-600  sm:w-auto active:bg-indigo-600  hover:bg-indigo-700 focus:outline-none focus:ring"
                onClick={() => setIsOpen(true)}
              >
                           {t("demomodulebutton")}
              </button>
            </div>
          </div>
        </div>
      </section>


     
    {/* <div className="relative bg-white py-12">
      <div>
      <h2 class="max-w-5xl px-4 bg-white mb-4 font-sans text-3xl text-center font-semibold leading-none tracking-tight text-gray-900 sm:text-[60px] md:mx-auto">
        {t("demoprototypetitle")}
      </h2>
      <p className='text-gray-600 text-center mt-3 text-lg max-w-2xl mx-auto'>{t("demoprototypedescription")}</p>
      </div>
      <iframe
        style={{
          border: 'none',
          width: '100vw',
          height: height,
          margin: isMobile ? '0px' : '0', 
          padding: isMobile ? '12px' : '0', 
          outline: 'none' 
        }}
        src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2Fm2SxDJKgzkr9K5UCoTn4Ya%2FPrototype-system-Invu%3Fnode-id%3D533-309%26node-type%3DCANVAS%26t%3DtOnevvtQivOeGHGX-1%26content-scaling%3Dfixed%26page-id%3D533%253A308&hide-ui=1"
        allowFullScreen
      />
    </div> */}
      <h2 class="mt-20 max-w-5xl px-4 bg-white mb-4 font-sans text-3xl text-center font-semibold leading-none tracking-tight text-gray-900 sm:text-[60px] md:mx-auto">
        {t("demoprototypetitle")}
      </h2>
      <p className='text-gray-600 text-center mt-3 text-lg max-w-2xl mx-auto'>{t("demoprototypedescription")}</p>

      <iframe
        src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2Fm2SxDJKgzkr9K5UCoTn4Ya%2FPrototype-system-Invu%3Fnode-id%3D533-309%26node-type%3DCANVAS%26t%3DtOnevvtQivOeGHGX-1%26content-scaling%3Dfixed%26page-id%3D533%253A308&hide-ui=1"
        // src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2Fm2SxDJKgzkr9K5UCoTn4Ya%2FPrototype-system-Invu%3Fnode-id%3D533-309%26node-type%3DCANVAS%26t%3DtOnevvtQivOeGHGX-1%26scaling%3Dscale-down%26content-scaling%3Dfixed%26page-id%3D533%253A308&hide-ui=1"
        allowFullScreen
        style={{
          width: '100%',
          height: height,
          // margin: isMobile ? '0px' : '0', 
          // padding: isMobile ? '12px' : '0', 
          border: 'none',
        }}
      />


    <div className="mt-8 px-4 py-0 mx-auto text-left sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 ">
        <div className="max-w-xl mb-4 sm:text-left lg:max-w-5xl md:mb-12">
          <h2 className="max-w-5xl mb-6 font-sans text-3xl font-semibold leading-none tracking-tight text-gray-900 sm:text-[40px] md:mx-auto">
          {t("demotabstitle")}
          </h2>
          <p className='text-gray-500 text-xl'>
         {t("demotabsdescription")}
          </p>
        </div>
      </div>
      <Main_tabs_demo/>


      <section>
        <div class="max-w-screen-xl px-4 mt-24 mx-auto sm:px-6 lg:px-8">
       <div className="flex bg-gradient-to-r from-[#eff1fe] to-[#cad0fb] flex-col justify-between px-4 mx-auto lg:flex-row md:px-12 lg:max-w-scr sm:px-6 lg:px-12een-xl rounded-2xl">
      <div className="pt-16 mb-16 lg:mb-0 lg:pt-16 lg:max-w-2xl ">
        <div className="max-w-2xl mb-4">
          <h2 className="max-w-2xl mb-4 font-sans text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl sm:leading-none">
         {t("democtatitle")}
          </h2>
          <p className="text-base text-gray-700 md:text-lg">
        {t("democtadescription")} {" "}
          <span className='font-semibold'>{t("democtadescriptionspan")}</span>
          </p>
        </div>
        <div className="flex items-center">
        <button
        onClick={() => {
          setIsOpen(true);
        }}
            class="group flex mt-2 items-center justify-between gap-3 rounded-full border border-indigo-600 bg-indigo-600 px-5 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring"
          >
            <span
              class="font-medium text-white transition-colors group-hover:text-indigo-600 group-active:text-indigo-500"
            >
              {t("democtabutton")}
            </span>

            <span
              class="shrink-0 rounded-full border border-current bg-white p-2 text-indigo-600 group-active:text-indigo-500"
            >
              <svg
                class="h-5 w-5 rtl:rotate-180"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            </span>
          </button>
        </div>
      </div>
      <div>
        <img
          src="https://img.invupos.com/webinvu/CTA/AVATAR-CTA1.png"
          className="h-[415px] mx-auto xl:mr-32 md:max-w-sm lg:-mt-0 -mt-12"
          alt=""
        />
      </div>
    </div>
    </div>
    </section>

    <Industries_carousel_pa/>
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 ">
    <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <h2 className="max-w-2xl mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          {t("facturacionelectronicatitlesection4")}
        </h2>
      </div>
      <div className="flex flex-col max-w-screen-xl overflow-hidden bg-white border rounded shadow-sm lg:flex-row sm:mx-auto">
        <div className="relative lg:w-1/2">
          <img
            src="https://images.pexels.com/photos/9304567/pexels-photo-9304567.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            alt=""
            className="object-cover w-full lg:absolute h-80 lg:h-full"
          />
          <svg
            className="absolute top-0 right-0 hidden h-full text-white lg:inline-block"
            viewBox="0 0 20 104"
            fill="currentColor"
          >
            <polygon points="17.3036738 5.68434189e-14 20 5.68434189e-14 20 104 0.824555778 104" />
          </svg>
        </div>
        <div className="flex flex-col justify-center p-8 bg-white lg:p-16 lg:pl-10 lg:w-1/2">
          <h5 className="mb-3 text-3xl font-extrabold leading-none sm:text-4xl">
            {t("facturacionelectronicamaincardtitle")}
          </h5>
          <p className="mb-5 text-gray-800 text-md">
            {t("facturacionelectronicamaincarddescription")}
          </p>
          <div className="flex items-center ">
          <button 
              onClick={() => setIsOpen(true)}
              class="py-4 px-6 inline-flex justify-center text-lg items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent bg-indigo-600 text-white hover:bg-indigo-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="#">
                {t("facturacionelectronicamaincardbutton")}
              </button>
          </div>
        </div>
      </div>
    </div>

    <div className="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
      <div className="grid gap-4 row-gap-5 sm:grid-cols-2 lg:grid-cols-3">
        <div className="flex flex-col justify-between p-5 border rounded shadow-sm">
          <div>
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 text-indigo-600">
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-headset">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M4 14v-3a8 8 0 1 1 16 0v3" />
              <path d="M18 19c0 1.657 -2.686 3 -6 3" />
              <path d="M4 14a2 2 0 0 1 2 -2h1a2 2 0 0 1 2 2v3a2 2 0 0 1 -2 2h-1a2 2 0 0 1 -2 -2v-3z" />
              <path d="M15 14a2 2 0 0 1 2 -2h1a2 2 0 0 1 2 2v3a2 2 0 0 1 -2 2h-1a2 2 0 0 1 -2 -2v-3z" />
            </svg>
            </div>
            <h3 className="mb-2 text-xl font-semibold ">{t("facturacionelectronicatitlecard1")}</h3>
            <p className="mb-3 text-base text-gray-900">
              {t("facturacionelectronicadescriptioncard1")}
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-between p-5 border rounded shadow-sm">
          <div>
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 text-indigo-600">
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-briefcase">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M3 7m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
              <path d="M8 7v-2a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v2" />
              <path d="M12 12l0 .01" />
              <path d="M3 13a20 20 0 0 0 18 0" />
            </svg>
            </div>
            <h3 className="mb-2 text-xl font-semibold ">{t("facturacionelectronicatitlecard2")}</h3>
            <p className="mb-3 text-base text-gray-900">
              {t("facturacionelectronicadescriptioncard2")}
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-between p-5 border rounded shadow-sm">
          <div>
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 text-indigo-600">
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-device-analytics">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M3 4m0 1a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v10a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1z" />
              <path d="M7 20l10 0" />
              <path d="M9 16l0 4" />
              <path d="M15 16l0 4" />
              <path d="M8 12l3 -3l2 2l3 -3" />
            </svg>
            </div>
            <h3 className="mb-2 text-xl font-semibold ">{t("facturacionelectronicatitlecard3")}</h3>
            <p className="mb-3 text-base text-gray-900">
              {t("facturacionelectronicadescriptioncard3")}
            </p>
          </div>
        </div>
      </div>
    </div>

     

<section>
        <div class="max-w-screen-xl px-4 lg:mt-32 mx-auto sm:px-6 lg:px-8">
       <div className="flex bg-gradient-to-r from-[#eff1fe] to-[#cad0fb] flex-col justify-between px-4 mx-auto lg:flex-row md:px-12 lg:max-w-scr sm:px-6 lg:px-12een-xl rounded-2xl">
      <div className="pt-16 lg:mb-16 mb-10 lg:mb-0 lg:pt-20 lg:max-w-lg lg:pr-5">
        <div className="max-w-xl mb-6">
          <h2 className="max-w-lg mb-6 font-sans text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl sm:leading-none">
          {t("posctacontacttitle")}
          </h2>
          <p className="text-base text-gray-700 md:text-lg">
          {t("posctacontactposdescription")}
          </p>
        </div>
        <div className="flex items-center">
        <button
        onClick={() => {
          setIsOpen(true);
        }}
            class="group flex mt-2 items-center justify-between gap-3 rounded-full border border-indigo-600 bg-indigo-600 px-5 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring"
          >
            <span
              class="font-medium text-white transition-colors group-hover:text-indigo-600 group-active:text-indigo-500"
            >
              {t("posctacontactbutton")}
            </span>

            <span
              class="shrink-0 rounded-full border border-current bg-white p-2 text-indigo-600 group-active:text-indigo-500"
            >
              <svg
                class="h-5 w-5 rtl:rotate-180"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            </span>
          </button>
        </div>
      </div>
      <div className="items-center flex content-center lg:mb-0 mb-12">
        <img
          src="https://img.invupos.com/webinvu/Point%20of%20sale%20module/mockupterminalnew.png"
          className="w-[450px] xl:mr-20 rounded-3xl"
          alt=""
        />
      </div>
    </div>
    </div>
    </section>



<FAQ_business_types />
    </>
  );
};

export default DemoFigma;
