import React, { useState, useEffect, useRef } from "react";
import useDocumentTitle from "../../useDocumentTitle";
// import Modal_started from "../Home/Modal_started";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import useLocalePrefix from "../utils/useLocalePrefix";
import FAQ_referrals from "./FAQ_referrals";
import Modal_referrals from "../Home/Modal_referrals";

import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

const Referrals = () => {
  const [isOpen, setIsOpen] = useState(false);

  useDocumentTitle("REFERIDOS | INVU POS");

  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };

  const location = useLocation();
  const prefix = useLocalePrefix();

  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    let scrollLeft = 0;
    let animationId;

    function animateScroll() {
      scrollLeft += 1; 

      if (scrollLeft >= container.scrollWidth - container.clientWidth) {
        clearInterval(animationId);
        setTimeout(() => { 
          scrollLeft = 0;
          animationId = setInterval(animateScroll, 1000 / 60);
        }, 2000);
      }

      container.scrollLeft = scrollLeft;
    }

    setTimeout(() => {
      animationId = setInterval(animateScroll, 1000 / 60); // Iniciar la animación
    }, 2000); 
 
    return () => clearInterval(animationId);
  }, []); 

  return (
      <>
      {isOpen && <Modal_referrals setIsOpen={setIsOpen} />}
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-32">
      <div className="grid gap-10 lg:grid-cols-2">
        <div className="lg:pr-10">
          <h1 class="font-bold xl:text-[48px] lg:text-6xl md:text-5xl text-5xl max-w-2xl text-[#002D33]">
          {t("referralssection1title")}
          </h1>
          <p class="mt-5 text-gray-700 leading-loose max-w-2xl font-light">
          {t("referralssection1description1")} <br/>
          {t("referralssection1description2")} <span className="font-semibold">{t("referralssection1descriptionspan2")}</span>
          </p>
          <div class="mt-7 grid gap-3 w-full sm:inline-flex">
            <button 
            onClick={() => setIsOpen(true)}
            class="py-4 px-5 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent bg-indigo-600 text-white hover:bg-indigo-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="#">
              {t("referralssection1button")}
            </button>
          </div>
        </div>
        <div>
          <img
            className="object-cover w-full h-56 rounded-2xl shadow-lg sm:h-96"
            src="https://img.invupos.com/webinvu/Referrals/referrals.jpg"
            alt=""
          />
        </div>
      </div>
    </div>

    <section className="">
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          {t("referralssection2title")}
        </h2>
      </div>
      <div className="grid gap-4 row-gap-5 sm:grid-cols-2 lg:grid-cols-3">
        <div className="flex flex-col justify-between p-5 rounded-2xl shadow-sm bg-indigo-50">
          <div>
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full text-white bg-gradient-to-r from-pirLightBlue to-pirPurple">
            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline  icon-tabler-cash-register">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M21 15h-2.5c-.398 0 -.779 .158 -1.061 .439c-.281 .281 -.439 .663 -.439 1.061c0 .398 .158 .779 .439 1.061c.281 .281 .663 .439 1.061 .439h1c.398 0 .779 .158 1.061 .439c.281 .281 .439 .663 .439 1.061c0 .398 -.158 .779 -.439 1.061c-.281 .281 -.663 .439 -1.061 .439h-2.5" />
                <path d="M19 21v1m0 -8v1" />
                <path d="M13 21h-7c-.53 0 -1.039 -.211 -1.414 -.586c-.375 -.375 -.586 -.884 -.586 -1.414v-10c0 -.53 .211 -1.039 .586 -1.414c.375 -.375 .884 -.586 1.414 -.586h2m12 3.12v-1.12c0 -.53 -.211 -1.039 -.586 -1.414c-.375 -.375 -.884 -.586 -1.414 -.586h-2" />
                <path d="M16 10v-6c0 -.53 -.211 -1.039 -.586 -1.414c-.375 -.375 -.884 -.586 -1.414 -.586h-4c-.53 0 -1.039 .211 -1.414 .586c-.375 .375 -.586 .884 -.586 1.414v6m8 0h-8m8 0h1m-9 0h-1" />
                <path d="M8 14v.01" />
                <path d="M8 17v.01" />
                <path d="M12 13.99v.01" />
                <path d="M12 17v.01" />
              </svg>
            </div>
            <h6 className="mb-2 font-semibold leading-5">{t("referralssection2itemtitle1")}</h6>
            <p className="mb-3 text-sm text-gray-900">
            {t("referralssection2itemdescription1")}
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-between p-5 rounded-2xl shadow-sm bg-indigo-50">
          <div>
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full text-white bg-gradient-to-r from-pirLightBlue to-pirPurple">
            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-zoom-money">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
              <path d="M21 21l-6 -6" />
              <path d="M12 7h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5" />
              <path d="M10 13v1m0 -8v1" />
            </svg>
            </div>
            <h6 className="mb-2 font-semibold leading-5">{t("referralssection2itemtitle2")}</h6>
            <p className="mb-3 text-sm text-gray-900">
              {t("referralssection2itemdescription2")}
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-between p-5 rounded-2xl shadow-sm bg-indigo-50">
          <div>
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full text-white bg-gradient-to-r from-pirLightBlue to-pirPurple">
            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-certificate">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M15 15m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
              <path d="M13 17.5v4.5l2 -1.5l2 1.5v-4.5" />
              <path d="M10 19h-5a2 2 0 0 1 -2 -2v-10c0 -1.1 .9 -2 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -1 1.73" />
              <path d="M6 9l12 0" />
              <path d="M6 12l3 0" />
              <path d="M6 15l2 0" />
            </svg>
            </div>
            <h6 className="mb-2 font-semibold leading-5">{t("referralssection2itemtitle3")}</h6>
            <p className="mb-3 text-sm text-gray-900">
              {t("referralssection2itemdescription3")}
            </p>
          </div>
        </div>
        
      </div>
    </div>
    </section>


    <section>
        <div class="max-w-screen-xl px-4 mt-28 mx-auto sm:px-6 lg:px-8">
          <div class="flex flex-col w-full mb-0 text-center">
            <div className="max-w-3xl mb-5 md:mx-auto sm:text-center lg:max-w-3xl md:mb-0">
            <div>
              </div>
              <h2 className="max-w-3xl mb-2 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
             {t("referralstitlecarousel1")} <span className="text-blue-600">{t("referralstitlecarouselspan")}</span> {t("referralstitlecarousel2")}
              </h2>
            </div>
          </div>
        </div>
      </section>
      <div className="container mx-auto">
        <div className="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8 md:px-0">
          <CarouselProvider
            className="lg:block hidden"
            naturalSlideWidth={100}
            isIntrinsicHeight={true}
            totalSlides={10}
            visibleSlides={2}
            step={1}
            infinite={true}
          >
            <div className="w-full relative flex items-center justify-center">
              <ButtonBack
                role="button"
                aria-label="slide backward"
                className="absolute z-30 left-0 ml-8 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer"
                id="prev"
              >
                <svg
                  width={8}
                  height={14}
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 1L1 7L7 13"
                    stroke="white"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </ButtonBack>
              <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
                <Slider>
                  <div
                    id="slider"
                    className="h-full flex lg:gap-4 md:gap-6 gap-12 items-center justify-start transition ease-out duration-700"
                  >
                    
                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20mexico/clientes/littlecaesarsphoto.jpg"
                          alt="Little Caesars"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          <div className="flex w-[220px] h-full items-end mx-auto pb-3">
                            <img
                              src="https://img.invupos.com/invu%20mexico/clientes/Little%20Caesars.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20mexico/clientes/krispykremephoto.jpg"
                          alt="Krispy Kreme"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          <div className="flex w-44 h-full items-end mx-auto">
                            <img
                              src="https://www.clutch.com/wp-content/uploads/2020/04/krispy-kreme-logo-white.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20mexico/clientes/olivegardephoto.jpg"
                          alt="Olive Garden"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          <div className="flex w-32 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20mexico/clientes/Olivegardenlogo.webp"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20mexico/clientes/Cinnabonphoto.jpeg"
                          alt="Cinnabon"
                          className="object-cover h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          <div className="flex w-40 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20mexico/clientes/cinnabon-logo.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/bbqchicken3.jpg"
                          alt="bbq chicken"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          <div className="flex w-[230px] h-full items-end mx-auto pb-3">
                            <img
                              src="https://img.invupos.com/invu%20costa%20rica/images/bbq%20chicken.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/cafeunido2.jpg"
                          alt="café unido"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          <div className="flex w-[150px] h-full items-end mx-auto pb-3">
                            <img
                              src="https://img.invupos.com/invu%20costa%20rica/images/cafeunido_logo.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/krumebacker3.jpg"
                          alt="Krume Bäcker"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-4">
                          <div className="flex w-[150px] h-full items-end mx-auto pb-3">
                            <img
                              src="https://img.invupos.com/invu%20costa%20rica/images/Krume.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/Manglebar.jpg"
                          alt="Mangle"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-2">
                          <div className="flex w-[160px] h-full items-end mx-auto pb-3">
                            <img
                              src="https://img.invupos.com/invu%20costa%20rica/images/manglebar.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/pedromandinga.jpg"
                          alt="Pedro Mandinga Rum Bar"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-0">
                          <div className="flex w-[200px] h-full items-end mx-auto pb-3">
                            <img
                              src="https://img.invupos.com/invu%20costa%20rica/images/pedromandinga.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                       {/* Star new Items */}
                       <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/thelookout_rest.jpg"
                          alt="TheLookout"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a7beff] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#001489]">Costa Rica</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-8">
                          
                          <div className="flex w-52 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20costa%20rica/images/thelookout_logo_white.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>
                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/hotelbananaazul.jpg"
                          alt="Hotel Banana Azul"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a7beff] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#001489]">Costa Rica</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          
                          <div className="flex w-40 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20costa%20rica/images/HotelBananaAzul.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>
                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/Bardo_bar1.jpg"
                          alt="Bardo WineBar"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a7beff] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#001489]">Costa Rica</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-0 ">
                          
                          <div className="flex w-44 mt-6 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20costa%20rica/images/bardo_logo.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>
                    {/* End New Items */}

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/invu%20mexico/clientes/despechomx-photo1.jpg"
                          alt="DespechoMX"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-8">
                          
                          <div className="flex w-44 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20mexico/clientes/despechomx-logo-transformed.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/invu%20mexico/clientes/blendstationphoto.jpg"
                          alt="Blend Station"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-8">
                          
                          <div className="flex w-44 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20mexico/clientes/Blendstation.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/invu%20mexico/clientes/ringnightclub1.jpg"
                          alt="Ring night club"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-8">
                          
                          <div className="flex w-44 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20mexico/clientes/Ringsocialroom.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/invu%20mexico/el9yucatecophoto.jpg"
                          alt="El 9 Yucateco"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          
                          <div className="flex w-24 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20mexico/clientes/el9yucateco1.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20mexico/clientes/mandrakephoto1.jpg"
                          alt="Mandrake Café"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          <div className="flex w-42 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20mexico/clientes/Mandrake%20cafe.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>


                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20mexico/clientes/Ninjaparkphoto.webp"
                          alt="Ninja Park"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ffed41] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#442304]">Venezuela</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          <div className="flex w-44 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20mexico/clientes/ninjaparklogo.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/CHURROMANIA.jpg"
                          alt="Churromania"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ffed41] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#442304]">Venezuela</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          <div className="flex w-44 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20costa%20rica/images/churromania_logo.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>



                    {/* <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20mexico/clientes/Elringbarphoto.jpg"
                          alt="El ring bar"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          <div className="flex w-24 h-full items-end mx-auto">
                            <img
                              src=""
                            />
                          </div>
                        </div>
                      </div>
                    </Slide> */}

                  </div>
                </Slider>
              </div>
              <ButtonNext
                role="button"
                aria-label="slide forward"
                className="absolute z-30 right-0 mr-8 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
                id="next"
              >
                <svg
                  width={8}
                  height={14}
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L7 7L1 13"
                    stroke="white"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </ButtonNext>
            </div>
          </CarouselProvider>

          {/* Carousel for tablet and medium size devices */}
          <CarouselProvider
            className="lg:hidden md:block hidden"
            naturalSlideWidth={100}
            isIntrinsicHeight={true}
            totalSlides={16}
            visibleSlides={2}
            step={1}
            infinite={true}
          >
            <div className="w-full relative flex items-center justify-center">
              <ButtonBack
                role="button"
                aria-label="slide backward"
                className="absolute z-30 left-0 ml-8 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer"
                id="prev"
              >
                <svg
                  width={8}
                  height={14}
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 1L1 7L7 13"
                    stroke="white"
                    strokeWidth={3}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </ButtonBack>
              <div className="w-full h-full  mx-auto overflow-x-hidden overflow-y-hidden">
                <Slider>
                  <div
                    id="slider"
                    className="h-full  flex lg:gap-8 md:gap-6 gap-14 items-center justify-start transition ease-out duration-700"
                  >

                        <Slide>
                        <div className="flex flex-shrink-0 relative w-full sm:w-72 rounded-3xl">
                          <img
                            src="https://img.invupos.com/invu%20mexico/clientes/littlecaesarsphoto.jpg"
                            className="object-cover object-center h-96 w-full rounded-3xl"
                          />
                          <div>
                            <div className="absolute inset-x-0 text-center mt-8">
                              <span
                                class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                              >
                                <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                              </span>
                            </div>
                          </div>

                          <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                            <div className="flex w-[220px]  h-full items-end mx-auto">
                              <img
                                src="https://img.invupos.com/invu%20mexico/clientes/Little%20Caesars.png"
                              />
                            </div>
                          </div>

                        </div>
                      </Slide>

                      <Slide>
                        <div className="flex flex-shrink-0 relative w-full sm:w-72 rounded-3xl">
                          <img
                            src="https://img.invupos.com/invu%20mexico/clientes/krispykremephoto.jpg"
                            className="object-cover object-center h-96 w-full rounded-3xl"
                          />
                          <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                            <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                              <div className="flex w-44 h-full items-end mx-auto">
                                <img
                                  src="https://www.clutch.com/wp-content/uploads/2020/04/krispy-kreme-logo-white.png"
                                />
                              </div>
                            </div>
                        </div>
                      </Slide>

                      <Slide>
                        <div className="flex flex-shrink-0 relative w-full sm:w-72 rounded-3xl">
                          <img
                            src="https://img.invupos.com/invu%20mexico/clientes/olivegardephoto.jpg"
                            className="object-cover object-center h-96 w-full rounded-3xl"
                          />
                          <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                            <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                              <div className="flex w-32 h-full items-end mx-auto">
                                <img
                                  src="https://img.invupos.com/invu%20mexico/clientes/Olivegardenlogo.webp"
                                />
                              </div>
                            </div>
                        </div>
                      </Slide>

                      <Slide>
                        <div className="flex flex-shrink-0 relative w-full sm:w-72 rounded-3xl">
                          <img
                            src="https://img.invupos.com/invu%20mexico/clientes/Cinnabonphoto.jpeg"
                            className="object-cover object-center h-96 w-full rounded-3xl"
                          />
                          <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                            <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                              <div className="flex w-40 h-full items-end mx-auto">
                                <img
                                  src="https://img.invupos.com/invu%20mexico/clientes/cinnabon-logo.png"
                                />
                              </div>
                            </div>
                        </div>
                      </Slide>

                      <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/bbqchicken3.jpg"
                          alt="bbq chicken"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          <div className="flex w-[230px] h-full items-end mx-auto pb-3">
                            <img
                              src="https://img.invupos.com/invu%20costa%20rica/images/bbq%20chicken.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/cafeunido2.jpg"
                          alt="café unido"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          <div className="flex w-[150px] h-full items-end mx-auto pb-3">
                            <img
                              src="https://img.invupos.com/invu%20costa%20rica/images/cafeunido_logo.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/krumebacker3.jpg"
                          alt="Krume Bäcker"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-4">
                          <div className="flex w-[150px] h-full items-end mx-auto pb-3">
                            <img
                              src="https://img.invupos.com/invu%20costa%20rica/images/Krume.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/Manglebar.jpg"
                          alt="Mangle"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-2">
                          <div className="flex w-[160px] h-full items-end mx-auto pb-3">
                            <img
                              src="https://img.invupos.com/invu%20costa%20rica/images/manglebar.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/pedromandinga.jpg"
                          alt="Pedro Mandinga Rum Bar"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-0">
                          <div className="flex w-[200px] h-full items-end mx-auto pb-3">
                            <img
                              src="https://img.invupos.com/invu%20costa%20rica/images/pedromandinga.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    {/* End PA */}

                    <Slide>
                        <div className="flex flex-shrink-0 relative w-full sm:w-72 rounded-3xl">
                          <img
                            src="https://img.invupos.com/invu%20costa%20rica/images/thelookout_rest.jpg"
                            className="object-cover object-center h-96 w-full rounded-3xl"
                          />
                          <div>
                            <div className="absolute inset-x-0 text-center mt-8">
                              <span
                                class="inline-flex items-center justify-center rounded-full bg-[#a7beff] px-4 py-0.5 text-purple-700"
                              >
                                <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#001489]">Costa Rica</p> {""}
                              </span>
                            </div>
                          </div>
                          <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                            <div className="flex w-52 h-full items-end mx-auto">
                              <img
                                src="https://img.invupos.com/invu%20costa%20rica/images/thelookout_logo_white.png"
                              />
                            </div>
                          </div>
                        </div>
                      </Slide>

                      <Slide>
                        <div className="flex flex-shrink-0 relative w-full sm:w-72 rounded-3xl">
                          <img
                            src="https://img.invupos.com/invu%20costa%20rica/images/hotelbananaazul.jpg"
                            className="object-cover object-center h-96 w-full rounded-3xl"
                          />
                          <div>
                            <div className="absolute inset-x-0 text-center mt-8">
                              <span
                                class="inline-flex items-center justify-center rounded-full bg-[#a7beff] px-4 py-0.5 text-purple-700"
                              >
                                <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#001489]">Costa Rica</p> {""}
                              </span>
                            </div>
                          </div>
                          <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                            <div className="flex w-40 h-full items-end mx-auto">
                              <img
                                src="https://img.invupos.com/invu%20costa%20rica/images/HotelBananaAzul.png"
                              />
                            </div>
                          </div>
                        </div>
                      </Slide>

                      <Slide>
                        <div className="flex flex-shrink-0 relative w-full sm:w-72 rounded-3xl">
                          <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/Bardo_bar1.jpg"
                            className="object-cover object-center h-96 w-full rounded-3xl"
                          />
                          <div>
                            <div className="absolute inset-x-0 text-center mt-8">
                              <span
                                class="inline-flex items-center justify-center rounded-full bg-[#a7beff] px-4 py-0.5 text-purple-700"
                              >
                                <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#001489]">Costa Rica</p> {""}
                              </span>
                            </div>
                          </div>
                          <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-0">
                            <div className="flex w-44 mt-6 h-full items-end mx-auto">
                              <img
                               src="https://img.invupos.com/invu%20costa%20rica/images/bardo_logo.png"
                              />
                            </div>
                          </div>
                        </div>
                      </Slide>

                      {/* END CR */}

                        <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/invu%20mexico/clientes/despechomx-photo1.jpg"
                          alt="DespechoMX"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-8">
                          
                          <div className="flex w-44 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20mexico/clientes/despechomx-logo-transformed.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/invu%20mexico/clientes/blendstationphoto.jpg"
                          alt="Blend Station"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-8">
                          
                          <div className="flex w-44 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20mexico/clientes/Blendstation.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/invu%20mexico/clientes/ringnightclub1.jpg"
                          alt="Ring night club"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-8">
                          
                          <div className="flex w-44 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20mexico/clientes/Ringsocialroom.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                      <Slide>
                        <div className="flex flex-shrink-0 relative w-full sm:w-72 rounded-3xl">
                          <img
                            src="https://img.invupos.com/invu%20mexico/el9yucatecophoto.jpg"
                            className="object-cover object-center h-96 w-full rounded-3xl"
                          />
                          <div>
                            <div className="absolute inset-x-0 text-center mt-8">
                              <span
                                class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                              >
                                <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                              </span>
                            </div>
                          </div>
                          <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                            <div className="flex w-24 h-full items-end mx-auto">
                              <img
                                src="https://img.invupos.com/invu%20mexico/clientes/el9yucateco1.png"
                              />
                            </div>
                          </div>
                        </div>
                      </Slide>

                      <Slide>
                        <div className="flex flex-shrink-0 relative w-full sm:w-72 rounded-3xl">
                          <img
                            src="https://img.invupos.com/invu%20mexico/clientes/mandrakephoto1.jpg"
                            className="object-cover object-center h-96 w-full rounded-3xl"
                          />
                          <div>
                            <div className="absolute inset-x-0 text-center mt-8">
                              <span
                                class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                              >
                                <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                              </span>
                            </div>
                          </div>

                          <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                            <div className="flex w-42 h-full items-end mx-auto">
                              <img
                                src="https://img.invupos.com/invu%20mexico/clientes/Mandrake%20cafe.png"
                              />
                            </div>
                          </div>

                        </div>
                      </Slide>


                      <Slide>
                        <div className="flex flex-shrink-0 relative w-full sm:w-72 rounded-3xl">
                          <img
                            src="https://img.invupos.com/invu%20mexico/clientes/Ninjaparkphoto.webp"
                            className="object-cover object-center h-96 w-full rounded-3xl"
                          />
                            <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ffed41] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#442304]">Venezuela</p> {""}
                            </span>
                          </div>
                        </div>
                            <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                              <div className="flex w-44 h-full items-end mx-auto">
                                <img
                                 src="https://img.invupos.com/invu%20mexico/clientes/ninjaparklogo.png"
                                />
                              </div>
                            </div>
                        </div>
                      </Slide>

                      <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/CHURROMANIA.jpg"
                          alt="Churromania"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ffed41] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#442304]">Venezuela</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          <div className="flex w-44 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20costa%20rica/images/churromania_logo.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>



                      {/* <Slide>
                        <div className="flex flex-shrink-0 relative w-full sm:w-72 rounded-3xl">
                          <img
                            src="https://img.invupos.com/invu%20mexico/clientes/Elringbarphoto.jpg"
                            className="object-cover object-center h-96 w-full rounded-3xl"
                          />
                          <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6 rounded-3xl">
                            <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                              <div className="flex w-40 h-full items-end mx-auto">
                                <img
                                 src=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Slide> */}

                  </div>
                </Slider>
              </div>

              <ButtonNext
                role="button"
                aria-label="slide forward"
                className="absolute z-30 right-0 mr-8 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
                id="next"
              >
                <svg
                  width={8}
                  height={14}
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L7 7L1 13"
                    stroke="white"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </ButtonNext>
            </div>
          </CarouselProvider>

          {/* Carousel for mobile and Small size Devices */}
          <CarouselProvider
            className="block md:hidden "
            naturalSlideWidth={100}
            isIntrinsicHeight={true}
            totalSlides={19}
            visibleSlides={1}
            step={1}
            infinite={true}
          >
            <div className="w-full relative flex items-center justify-center">
              <ButtonBack
                role="button"
                aria-label="slide backward"
                className="absolute z-30 left-0 ml-8 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer"
                id="prev"
              >
                <svg
                  width={8}
                  height={14}
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 1L1 7L7 13"
                    stroke="white"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </ButtonBack>
              <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
                <Slider>
                  <div
                    id="slider"
                    className="h-full w-full flex lg:gap-8 gap-4 md:gap-6 items-center justify-start transition ease-out duration-700"
                  >

                    <Slide>
                        <div className="flex flex-shrink-0 relative w-full sm:w-72 rounded-3xl">
                          <img
                            src="https://img.invupos.com/invu%20mexico/clientes/littlecaesarsphoto.jpg"
                            className="object-cover object-center h-96 w-full rounded-3xl"
                          />
                            <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                            <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                              <div className="flex w-[220px]  h-full items-end mx-auto">
                                <img
                                  src="https://img.invupos.com/invu%20mexico/clientes/Little%20Caesars.png"
                                />
                              </div>
                            </div>
                        </div>
                      </Slide>

                      <Slide>
                        <div className="flex flex-shrink-0 relative w-full sm:w-72 rounded-3xl">
                          <img
                            src="https://img.invupos.com/invu%20mexico/clientes/krispykremephoto.jpg"
                            className="object-cover object-center h-96 w-full rounded-3xl"
                          />
                            <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                            <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                              <div className="flex w-44 h-full items-end mx-auto">
                                <img
                                  src="https://www.clutch.com/wp-content/uploads/2020/04/krispy-kreme-logo-white.png"
                                />
                              </div>
                            </div>
                        </div>
                      </Slide>

                      <Slide>
                        <div className="flex flex-shrink-0 relative w-full sm:w-72 rounded-3xl">
                          <img
                            src="https://img.invupos.com/invu%20mexico/clientes/olivegardephoto.jpg"
                            className="object-cover object-center h-96 w-full rounded-3xl"
                          />
                            <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>

                            <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                              <div className="flex w-32 h-full items-end mx-auto">
                                <img
                                  src="https://img.invupos.com/invu%20mexico/clientes/Olivegardenlogo.webp"
                                />
                              </div>
                            </div>
                        </div>
                      </Slide>

                      <Slide>
                        <div className="flex flex-shrink-0 relative w-full sm:w-72 rounded-3xl">
                          <img
                            src="https://img.invupos.com/invu%20mexico/clientes/Cinnabonphoto.jpeg"
                            className="object-cover object-center h-96 w-full rounded-3xl"
                          />
                            <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                            <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                              <div className="flex w-40 h-full items-end mx-auto">
                                <img
                                  src="https://img.invupos.com/invu%20mexico/clientes/cinnabon-logo.png"
                                />
                              </div>
                            </div>
                        </div>
                      </Slide>

                      <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/bbqchicken3.jpg"
                          alt="bbq chicken"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          <div className="flex w-[230px] h-full items-end mx-auto pb-3">
                            <img
                              src="https://img.invupos.com/invu%20costa%20rica/images/bbq%20chicken.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/cafeunido2.jpg"
                          alt="café unido"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          <div className="flex w-[150px] h-full items-end mx-auto pb-3">
                            <img
                              src="https://img.invupos.com/invu%20costa%20rica/images/cafeunido_logo.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/krumebacker3.jpg"
                          alt="Krume Bäcker"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-4">
                          <div className="flex w-[150px] h-full items-end mx-auto pb-3">
                            <img
                              src="https://img.invupos.com/invu%20costa%20rica/images/Krume.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/Manglebar.jpg"
                          alt="Mangle"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-2">
                          <div className="flex w-[160px] h-full items-end mx-auto pb-3">
                            <img
                              src="https://img.invupos.com/invu%20costa%20rica/images/manglebar.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/pedromandinga.jpg"
                          alt="Pedro Mandinga Rum Bar"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-0">
                          <div className="flex w-[200px] h-full items-end mx-auto pb-3">
                            <img
                              src="https://img.invupos.com/invu%20costa%20rica/images/pedromandinga.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                        <div className="flex flex-shrink-0 relative w-full sm:w-72 rounded-3xl">
                          <img
                            src="https://img.invupos.com/invu%20costa%20rica/images/thelookout_rest.jpg"
                            className="object-cover object-center h-96 w-full rounded-3xl"
                          />
                          <div>
                            <div className="absolute inset-x-0 text-center mt-8">
                              <span
                                class="inline-flex items-center justify-center rounded-full bg-[#a7beff] px-4 py-0.5 text-purple-700"
                              >
                                <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#001489]">Costa Rica</p> {""}
                              </span>
                            </div>
                          </div>
                          <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                            <div className="flex w-52 h-full items-end mx-auto">
                              <img
                                src="https://img.invupos.com/invu%20costa%20rica/images/thelookout_logo_white.png"
                              />
                            </div>
                          </div>
                        </div>
                      </Slide>

                      <Slide>
                        <div className="flex flex-shrink-0 relative w-full sm:w-72 rounded-3xl">
                          <img
                            src="https://img.invupos.com/invu%20costa%20rica/images/hotelbananaazul.jpg"
                            className="object-cover object-center h-96 w-full rounded-3xl"
                          />
                          <div>
                            <div className="absolute inset-x-0 text-center mt-8">
                              <span
                                class="inline-flex items-center justify-center rounded-full bg-[#a7beff] px-4 py-0.5 text-purple-700"
                              >
                                <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#001489]">Costa Rica</p> {""}
                              </span>
                            </div>
                          </div>
                          <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                            <div className="flex w-40 h-full items-end mx-auto">
                              <img
                                src="https://img.invupos.com/invu%20costa%20rica/images/HotelBananaAzul.png"
                              />
                            </div>
                          </div>
                        </div>
                      </Slide>

                      <Slide>
                        <div className="flex flex-shrink-0 relative w-full sm:w-72 rounded-3xl">
                          <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/Bardo_bar1.jpg"
                            className="object-cover object-center h-96 w-full rounded-3xl"
                          />
                          <div>
                            <div className="absolute inset-x-0 text-center mt-8">
                              <span
                                class="inline-flex items-center justify-center rounded-full bg-[#a7beff] px-4 py-0.5 text-purple-700"
                              >
                                <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#001489]">Costa Rica</p> {""}
                              </span>
                            </div>
                          </div>
                          <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-0">
                            <div className="flex w-44 mt-6 h-full items-end mx-auto">
                              <img
                               src="https://img.invupos.com/invu%20costa%20rica/images/bardo_logo.png"
                              />
                            </div>
                          </div>
                        </div>
                      </Slide>

                      <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/invu%20mexico/clientes/despechomx-photo1.jpg"
                          alt="DespechoMX"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-8">
                          
                          <div className="flex w-44 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20mexico/clientes/despechomx-logo-transformed.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/invu%20mexico/clientes/blendstationphoto.jpg"
                          alt="Blend Station"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-8">
                          
                          <div className="flex w-44 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20mexico/clientes/Blendstation.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/invu%20mexico/clientes/ringnightclub1.jpg"
                          alt="Ring night club"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-8">
                          
                          <div className="flex w-44 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20mexico/clientes/Ringsocialroom.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>
                    
                    <Slide>
                        <div className="flex flex-shrink-0 relative w-full sm:w-72 rounded-3xl">
                          <img
                            src="https://img.invupos.com/invu%20mexico/el9yucatecophoto.jpg"
                            className="object-cover object-center h-96 w-full rounded-3xl"
                          />
                           <div>
                            <div className="absolute inset-x-0 text-center mt-8">
                              <span
                                class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                              >
                                <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                              </span>
                            </div>
                          </div>
                            <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                              <div className="flex w-24 h-full items-end mx-auto">
                                <img
                                  src="https://img.invupos.com/invu%20mexico/clientes/el9yucateco1.png"
                                />
                              </div>
                            </div>
                        </div>
                      </Slide>

                      <Slide>
                        <div className="flex flex-shrink-0 relative w-full sm:w-72 rounded-3xl">
                          <img
                            src="https://lh3.googleusercontent.com/p/AF1QipNNbBvGd3uwpSIDeqSyFfAUGksIYIx8ndHM2Lz0=s680-w680-h510"
                            className="object-cover object-center h-96 w-full rounded-3xl"
                          />
                           <div>
                            <div className="absolute inset-x-0 text-center mt-8">
                              <span
                                class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                              >
                                <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                              </span>
                            </div>
                          </div>
                            <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                              <div className="flex w-42 h-full items-end mx-auto">
                                <img
                                  src="https://img.invupos.com/invu%20mexico/clientes/Mandrake%20cafe.png"
                                />
                              </div>
                            </div>
                        </div>
                      </Slide>

                      <Slide>
                        <div className="flex flex-shrink-0 relative w-full sm:w-72 rounded-3xl">
                          <img
                            src="https://img.invupos.com/invu%20mexico/clientes/Ninjaparkphoto.webp"
                            className="object-cover object-center h-96 w-full rounded-3xl"
                          />
                           <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ffed41] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#442304]">Venezuela</p> {""}
                            </span>
                          </div>
                        </div>
                            <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                              <div className="flex w-44 h-full items-end mx-auto">
                                <img
                                 src="https://img.invupos.com/invu%20mexico/clientes/ninjaparklogo.png"
                                />
                              </div>
                            </div>
                        </div>
                      </Slide>

                      <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/CHURROMANIA.jpg"
                          alt="Churromania"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ffed41] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#442304]">Venezuela</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          <div className="flex w-44 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20costa%20rica/images/churromania_logo.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                  </div>
                </Slider>
              </div>
              <ButtonNext
                role="button"
                aria-label="slide forward"
                className="absolute z-30 right-0 mr-8 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
                id="next"
              >
                <svg
                  width={8}
                  height={14}
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L7 7L1 13"
                    stroke="white"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </ButtonNext>
            </div>
          </CarouselProvider>
        </div>
      </div>


<section className="bg-gray-50 mt-10">
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div class="md:flex md:items-center md:justify-between mb-5">
      <div class="max-w-xl">
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl">
            {t("referralsindustriestitle")}
        </h2>
      </div>

      <button 
      onClick={() => setIsOpen(true)}
      class="py-4 px-5 inline-flex justify-center text-lg items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent bg-indigo-600 text-white hover:bg-indigo-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="#">
        {t("referralsindustriesbutton")}
      </button>
    </div>                
      <div className="grid gap-5 mb-8 md:grid-cols-2 lg:grid-cols-4">
        <div className="p-5 duration-300 transform bg-white border rounded-xl shadow-sm hover:-translate-y-2">
          <div className="mb-6">
          <img
            src="https://images.pexels.com/photos/761854/pexels-photo-761854.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            className="object-cover w-full h-52 rounded-xl"
            alt=""
          />
          </div>
          <h6 className="mb-2 font-semibold leading-5 text-lg">{t("referralsindustriescard1title")}</h6>
          <p className="text-md text-gray-900">
          {t("referralsindustriescard1description")}
          </p>
        </div>
        <div className="p-5 duration-300 transform bg-white border rounded-xl shadow-sm hover:-translate-y-2">
          <div className="mb-6">
          <img
            src="https://images.pexels.com/photos/3738387/pexels-photo-3738387.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            className="object-cover w-full h-52 rounded-xl"
            alt=""
          />
          </div>
          <h6 className="mb-2 font-semibold leading-5 text-lg">{t("referralsindustriescard2title")}</h6>
          <p className="text-md text-gray-900">
          {t("referralsindustriescard2description")}
          </p>
        </div>
        <div className="p-5 duration-300 transform bg-white border rounded-xl shadow-sm hover:-translate-y-2">
          <div className="mb-6">
          <img
            src="https://images.pexels.com/photos/1267696/pexels-photo-1267696.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            className="object-cover w-full h-52 rounded-xl"
            alt=""
          />
          </div>
          <h6 className="mb-2 font-semibold leading-5 text-lg">{t("referralsindustriescard3title")}</h6>
          <p className="text-md text-gray-900">
          {t("referralsindustriescard3description")}
          </p>
        </div>
        <div className="p-5 duration-300 transform bg-white border rounded-xl shadow-sm hover:-translate-y-2">
          <div className="mb-6">
          <img
            src="https://images.pexels.com/photos/3993320/pexels-photo-3993320.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            className="object-cover w-full h-52 rounded-xl"
            alt=""
          />
          </div>
          <h6 className="mb-2 font-semibold leading-5 text-lg">{t("referralsindustriescard4title")}</h6>
          <p className="text-md text-gray-900">
          {t("referralsindustriescard4description")}
          </p>
        </div>

        
      </div>
     
    </div>
    </section>

{/* 
    <section class="relative mt-16">
        <img
          class="absolute inset-0 object-[75%] sm:object-[25%] object-cover w-full h-full opacity-85 sm:opacity-100"
          src="https://img.invupos.com/webinvu/Referrals/imgctafinal.png"
          alt=""
        />
        <div className="absolute inset-0 bg-gray-900 bg-opacity-40"></div>

        <div class="hidden sm:block sm:inset-0 sm:absolute sm:bg-gradient-to-r sm:from-pirDarkGray sm:to-transparent"></div>

        <div class="relative max-w-screen-xl  px-4  sm:px-6 lg:px-8 py-32 mx-auto lg:h-4/6 lg:items-center lg:flex">
          <div class="max-w-3xl text-center sm:text-left">
            <div>
              <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-white">
                {t("referralsctauppercase")}
              </p>
            </div>
            <h1 class="text-3xl text-white font-extrabold sm:text-5xl">
              {t("referralsctatitle")}
            </h1>

            <p className="mt-5 max-w-3xl text-gray-200 leading-loose">
             {t("referralsctadescription1")}  <span className="font-semibold">{t("referralsctadescription2")}</span>
            </p>
          </div>
        </div>
      </section>


      
    
    <FAQ_referrals/> */}

    <FAQ_referrals/>

      </>
  )
}

export default Referrals