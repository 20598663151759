import React, { useState, useEffect, useRef } from "react";
import Help_CTA from "../Home/Help_CTA";
import Modal_started from "../Home/Modal_started";
import useDocumentTitle from "../../useDocumentTitle";
import FAQ_model_one from "./FAQ_model_one";
import Home from "../../pages/Home";

import { useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { Link, useLocation } from "react-router-dom";
import useLocalePrefix from "../utils/useLocalePrefix";

import Main_tabs_demo from "../modules/tabsdemo/Main_tabs_demo";
import CTA_home from "../Home/CTA_home";

const features = [
  {
    id: "1",
    name: "Getting started",
    description:
      "All you need to know about Invu and how we can help you succeed in a world of giants.",
    imgSrc:
      "https://img.invupos.com/webinvu/Help%20center%20module/Help%20center%202.jpeg",
    href: "/point-of-sale",
  },
  {
    id: "2",
    name: "Using Invu POS",
    description:
      "Learn about the main features of the app and how you can use it to run your business.",
    imgSrc:
      "https://img.invupos.com/webinvu/Help%20center%20module/Help%20center%203.jpg",
    href: "/products-overview",
  },
  {
    id: "3",
    name: "Invu Terminal",
    description:
      "With the Terminal, you can take payments and manage your POS from the same device.",
    imgSrc:
      "https://img.invupos.com/webinvu/Help%20center%20module/Help%20center%204.jpg",
    href: "/hardware-details",
  },
  {
    id: "4",
    name: "Hardware",
    description:
      "Find information to help troubleshootyour Invu POS. Take the hardest out of Hardware solutions.",
    imgSrc:
      "https://img.invupos.com/webinvu/Help%20center%20module/Help%20center%205.webp",
    href: "/hardware-details",
  },
];

const Help_center = () => {
  const [isOpen, setIsOpen] = useState(false);

  useDocumentTitle("SOPORTE | INVU POS ");
  const history = useHistory();

  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };

  const location = useLocation();
  const prefix = useLocalePrefix();

  // useEffect(() => {
  //   window.open("https://soporte.invupos.com/es-LA/support/solutions", "_blank");
  // }, []);

  const containerRef = useRef(null);
  
  useEffect(() => {
    const container = containerRef.current;
    let scrollLeft = 0;
    let animationId;

    function animateScroll() {
      scrollLeft += 1; 

      if (scrollLeft >= container.scrollWidth - container.clientWidth) {
        clearInterval(animationId);
        setTimeout(() => { 
          scrollLeft = 0;
          animationId = setInterval(animateScroll, 1000 / 60);
        }, 2000);
      }

      container.scrollLeft = scrollLeft;
    }

    setTimeout(() => {
      animationId = setInterval(animateScroll, 1000 / 60); // Iniciar la animación
    }, 2000); 
 
    return () => clearInterval(animationId); 
  }, []); 


  // const [height, setHeight] = useState('100vh');
  // const isMobile = window.innerWidth <= 768; 

  // useEffect(() => {
  //   const handleResize = () => {
  //     const width = window.innerWidth;
  //     if (width <= 480) {
  //       setHeight('45vh');
  //     } else if (width <= 640) {
  //       setHeight('50vh');
  //     } else if (width <= 768) {
  //       setHeight('60vh');
  //     } else if (width <= 1024) {
  //       setHeight('80vh');
  //     } else {
  //       setHeight('100vh');
  //     }
  //   };

  //   window.addEventListener('resize', handleResize);
  //   handleResize(); // Set initial value

  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

  const [height, setHeight] = useState('1000px');
  const isMobile = window.innerWidth <= 768; 

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      if (width <= 640) {
        setHeight('350px'); // Móviles pequeños
      } else if (width <= 768) {
        setHeight('600px'); // Tabletas en orientación vertical
      } else if (width <= 1024) {
        setHeight('650px'); // Tabletas en orientación horizontal y laptops pequeñas
      } else if (width <= 1280) {
        setHeight('800px'); // Laptops medianas y monitores pequeños
      } else {
        setHeight('885px'); // Monitores grandes y resoluciones mayores
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial value

    return () => window.removeEventListener('resize', handleResize);
  }, []);


  
  return (
    <>
      {/* <div className="bg-white">
        <div className="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8 mt-10">
          <div className="flex flex-col mb-6 lg:flex-row md:mb-10">
            <div className="lg:w-1/2 ">
              <div class="w-10 mb-6 h-10 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange flex justify-center items-center"></div>

              <h2 className="mb-3 text-xs font-semibold tracking-widest text-black uppercase title-font text-pirGray-100">
                &mdash; {t("resourcesmoretoolssupportuppercase")}
              </h2>
              <h2 className="max-w-md mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none xl:max-w-lg">
                {t("resourcesmoretoolssupporttitle")}
              </h2>
            </div>
            <div className="flex items-end lg:w-1/2">
              <p className="text-base text-gray-700 md:text-medium">
                {t("resourcesmoretoolssupportdescription")}
              </p>
            </div>
          </div>
          <div className="group relative">
            <div className="bg-gray-200 aspect-w-1 cursor-pointer aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 ">
              <img
                className="object-cover w-full h-86 sm:h-96 rounded-lg"
                src="https://img.invupos.com/webinvu/Help%20center%20module/Help%20center%201.avif"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <section className="bg-pirBlack mt-24">
        <div class="px-4 py-24 mx-auto max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid grid-cols-1 gap-12 md:grid-cols-2 lg:grid-cols-2 ">

              <div>
                <a class="grid grid-cols-1  overflow-hidden rounded-lg group sm:grid-cols-3">
                  <div class="relative">
                    <img
                      class="absolute inset-0 object-cover w-full h-full"
                      src="https://img.invupos.com/webinvu/Help%20center%20module/Help%20center%202.jpeg"
                      alt=""
                    />
                  </div>
                  <div class="p-8 sm:col-span-2 bg-pirDarkGray">
                    <ul class="flex space-x-1">
                      <li class="inline-block px-3 py-1 text-xs  font-semibold text-white bg-blue-600 rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer">
                        {t("resourcesmoretoolssupportcardglobaluppercase")}
                      </li>
                    </ul>
                    <h5 class="mt-4 font-bold text-white underline">
                      {t("resourcesmoretoolssupportcard1title")}
                    </h5>

                    <p class="mt-2 text-sm text-gray-500">
                      {t("resourcesmoretoolssupportcard1description")}
                    </p>
                  </div>
                </a>
              </div>

              <div>
                <a class="grid grid-cols-1  overflow-hidden rounded-lg group sm:grid-cols-3">
                  <div class="relative">
                    <img
                      class="absolute inset-0 object-cover w-full h-full"
                      src="https://img.invupos.com/webinvu/Help%20center%20module/Help%20center%203.jpg"
                      alt=""
                    />
                  </div>
                  <div class="p-8 sm:col-span-2 bg-pirDarkGray">
                    <ul class="flex space-x-1">
                      <li class="inline-block px-3 py-1 text-xs  font-semibold text-white bg-blue-600 rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer">
                        {t("resourcesmoretoolssupportcardglobaluppercase")}
                      </li>
                    </ul>
                    <h5 class="mt-4 font-bold text-white underline">
                      {t("resourcesmoretoolssupportcard2title")}
                    </h5>

                    <p class="mt-2 text-sm text-gray-500">
                      {t("resourcesmoretoolssupportcard2description")}
                    </p>
                  </div>
                </a>
              </div>

              <div>
                <a class="grid grid-cols-1  overflow-hidden rounded-lg group sm:grid-cols-3">
                  <div class="relative">
                    <img
                      class="absolute inset-0 object-cover w-full h-full"
                      src="https://img.invupos.com/webinvu/Help%20center%20module/Help%20center%204.jpg"
                      alt=""
                    />
                  </div>
                  <div class="p-8 sm:col-span-2 bg-pirDarkGray">
                    <ul class="flex space-x-1">
                      <li class="inline-block px-3 py-1 text-xs  font-semibold text-white bg-blue-600 rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer">
                        {t("resourcesmoretoolssupportcardglobaluppercase")}
                      </li>
                    </ul>
                    <h5 class="mt-4 font-bold text-white underline">
                      {t("resourcesmoretoolssupportcard3title")}
                    </h5>

                    <p class="mt-2 text-sm text-gray-500">
                      {t("resourcesmoretoolssupportcard3description")}
                    </p>
                  </div>
                </a>
              </div>

              <div>
                <a class="grid grid-cols-1  overflow-hidden rounded-lg group sm:grid-cols-3">
                  <div class="relative">
                    <img
                      class="absolute inset-0 object-cover w-full h-full"
                      src="https://img.invupos.com/webinvu/Help%20center%20module/Help%20center%205.webp"
                      alt=""
                    />
                  </div>
                  <div class="p-8 sm:col-span-2 bg-pirDarkGray">
                    <ul class="flex space-x-1">
                      <li class="inline-block px-3 py-1 text-xs  font-semibold text-white bg-blue-600 rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer">
                        {t("resourcesmoretoolssupportcardglobaluppercase")}
                      </li>
                    </ul>
                    <h5 class="mt-4 font-bold text-white underline">
                      {t("resourcesmoretoolssupportcard4title")}
                    </h5>

                    <p class="mt-2 text-sm text-gray-500 max-w-4xl">
                      {t("resourcesmoretoolssupportcard4description")}
                    </p>
                  </div>
                </a>
              </div>

          </div>
        </div>
      </section>

      <div className="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8 mt-10">
        <div className="bg-gray-50 rounded-lg grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1  md:gap-12 gap-14  lg:py-12 py-10 md:px-12 px-4 bg-gradient-to-r from-pink-500 via-red-500 to-pirOrange">
          <div>
            <div className="flex items-center justify-center w-16 h-16  rounded-full bg-indigo-50 ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-7 w-7"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                />
              </svg>
            </div>
            <h3 className=" text-xl leading-5 font-semibold text-white lg:mt-10 mt-6 ">
              {t("resourcesmoretoolssupportsection3card1title")}
            </h3>
            <p className=" text-base leading-6 font-normal text-white mt-4 lg:w-full md:w-9/12 w-full">
             {t("resourcesmoretoolssupportsection3card1description")}
            </p>
          </div>

          <div>
            <div className="flex items-center justify-center w-16 h-16  rounded-full bg-indigo-50">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-7 w-7"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                />
              </svg>
            </div>
            <h3 className=" text-xl leading-5 font-semibold text-white lg:mt-10 mt-8 ">
              {t("resourcesmoretoolssupportsection3card2title")}
            </h3>
            <p className=" text-base leading-6 font-normal text-white mt-4 lg:w-full md:w-9/12 w-full">
              {t("resourcesmoretoolssupportsection3card2description")}
            </p>
          </div>

          <div>
            <div className="flex items-center justify-center w-16 h-16  rounded-full bg-indigo-50">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-7 w-7"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            </div>
            <h3 className=" text-xl leading-5 font-semibold text-white lg:mt-10 mt-6">
              {t("resourcesmoretoolssupportsection3card3title")}
            </h3>
            <p className=" text-base leading-6 font-normal text-white mt-4 lg:w-full md:w-9/12 w-full">
              {t("resourcesmoretoolssupportsection3card3description")}
            </p>
          </div>

          <div>
            <div className="flex items-center justify-center w-16 h-16  rounded-full bg-indigo-50">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-7 w-7"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <h3 className=" text-xl leading-5 font-semibold text-white lg:mt-10 mt-6 ">
              {t("resourcesmoretoolssupportsection3card4title")}
            </h3>
            <p className=" text-base leading-6 font-normal text-white mt-4 lg:w-full md:w-9/12 w-full">
              {t("resourcesmoretoolssupportsection3card4description")}
            </p>
          </div>
        </div>
      </div> */}

{isOpen && <Modal_started setIsOpen={setIsOpen} />} 


<section className="bg-[#121212]">
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-32">
      <div className="grid gap-16 lg:grid-cols-2">
        <div className="lg:pr-10 content-center">
          <h1 class="font-bold xl:text-[50px] lg:text-5xl md:text-5xl text-4xl text-white">
          {t("supportsection1title")}
          </h1>
          <p class="mt-5 text-gray-300 text-lg leading-loose max-w-2xl font-light">
         {t("supportsection1description")}
          </p>
          <div class="mt-7 grid gap-3 w-full sm:inline-flex">
            {/* <button 
            onClick={() => setIsOpen(true)}
            class="py-4 px-5 inline-flex justify-center text-lg items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent bg-indigo-600 text-white hover:bg-indigo-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="#">
              {t("supportsection1button")}
            </button> */}
            <a
            href="https://soporte.invupos.com/es-LA/support/tickets/new"
            target="_blank"
            class="py-4 px-5 inline-flex justify-center text-lg items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent bg-indigo-600 text-white hover:bg-indigo-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
            >
            {t("supportsection1button")}
            </a>
          </div>
          <div class="mt-6 lg:mt-12 mb-6">
                    <span class="text-xs font-semibold text-gray-500 uppercase tracking-widest">{t("availableinhero")}</span>
                    <div class="mt-4 flex gap-x-8">
                      <a href="https://bit.ly/4cXCHQm" target="_blank">
                        <svg class="icon text-gray-500 hover:text-gray-800 transition duration-300 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                            <path fill="currentColor" d="m3.001 5.479l7.377-1.016v7.127H3zm0 13.042l7.377 1.017v-7.04H3zm8.188 1.125L21.001 21v-8.502h-9.812zm0-15.292v7.236h9.812V3z"/>
                        </svg>
                      </a>
                      <a href="https://bit.ly/3W8xWN4" target="_blank">
                        <svg class="icon text-gray-500 hover:text-gray-800 transition duration-300 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 315">
                            <path fill="currentColor" d="M213.803 167.03c.442 47.58 41.74 63.413 42.197 63.615c-.35 1.116-6.599 22.563-21.757 44.716c-13.104 19.153-26.705 38.235-48.13 38.63c-21.05.388-27.82-12.483-51.888-12.483c-24.061 0-31.582 12.088-51.51 12.871c-20.68.783-36.428-20.71-49.64-39.793c-27-39.033-47.633-110.3-19.928-158.406c13.763-23.89 38.36-39.017 65.056-39.405c20.307-.387 39.475 13.662 51.889 13.662c12.406 0 35.699-16.895 60.186-14.414c10.25.427 39.026 4.14 57.503 31.186c-1.49.923-34.335 20.044-33.978 59.822M174.24 50.199c10.98-13.29 18.369-31.79 16.353-50.199c-15.826.636-34.962 10.546-46.314 23.828c-10.173 11.763-19.082 30.589-16.678 48.633c17.64 1.365 35.66-8.964 46.64-22.262"/>
                        </svg>
                      </a>
                      <a href="https://bit.ly/4cJt3Be" target="_blank">
                          <svg class="icon text-gray-500 hover:text-gray-800 transition duration-300 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 20 20">
                              <path fill="currentColor" fill-rule="evenodd" d="M12.735 1.828L13.6.254a.172.172 0 0 0-.3-.164l-.874 1.59a5.987 5.987 0 0 0-2.428-.508c-.869 0-1.69.181-2.424.506L6.698.09a.171.171 0 0 0-.232-.069a.169.169 0 0 0-.069.232l.866 1.572c-1.7.874-2.85 2.537-2.849 4.447l11.166-.001c0-1.91-1.146-3.57-2.845-4.444m-5.278 2.43a.467.467 0 1 1 .001-.934a.467.467 0 0 1 0 .935M15.542 6.7l.002 8.012c0 .481-.262.897-.648 1.127a1.31 1.31 0 0 1-.675.19l-.904.001v2.734a1.237 1.237 0 0 1-1.489 1.21a1.238 1.238 0 0 1-.99-1.209V16.03H9.163v2.735A1.237 1.237 0 0 1 7.925 20a1.239 1.239 0 0 1-1.238-1.235V16.03h-.901c-.521 0-.967-.3-1.182-.736a1.298 1.298 0 0 1-.141-.581l-.002-8.01zM2.74 6.47c.684 0 1.24.553 1.24 1.234v5.17a1.238 1.238 0 0 1-1.922 1.03a1.231 1.231 0 0 1-.557-1.03L1.5 7.703c0-.68.555-1.234 1.24-1.234m14.52-.001c.684 0 1.24.552 1.24 1.234v5.169c0 .683-.555 1.235-1.239 1.235c-.685 0-1.24-.552-1.24-1.235V7.702c0-.682.554-1.235 1.238-1.234M12.54 3.325a.467.467 0 1 1 0 .933a.467.467 0 1 1 0-.933"/>
                          </svg>
                      </a>
                    </div>
                  </div>
        </div>
        <div>
          <img
            className="object-cover w-full h-80 rounded-2xl shadow-lg sm:h-[480px]"
            src="https://images.pexels.com/photos/7709157/pexels-photo-7709157.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" 
            alt=""
          />
        </div>
      </div>
    </div>
    </section>

        
    {/* <div className="relative bg-white py-12">
      <div className='py-8'>
      <h2 class="max-w-5xl mt-12 px-4 bg-white mb-5 font-sans text-3xl text-center font-semibold leading-none tracking-tight text-gray-900 sm:text-[60px] md:mx-auto">
        {t("spprototypetitle")}
      </h2>
      <p className='text-gray-600 text-center mt-3 text-lg max-w-2xl mx-auto'>{t("spprototypedescription")}</p>
      </div>
      <iframe
        style={{
          border: 'none',
          width: '100vw',
          height: height,
          margin: isMobile ? '0px' : '0', 
          padding: isMobile ? '12px' : '0', 
          outline: 'none' 
        }}
        src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2Fm2SxDJKgzkr9K5UCoTn4Ya%2FPrototype-system-Invu%3Fnode-id%3D533-309%26node-type%3DCANVAS%26t%3DtOnevvtQivOeGHGX-1%26content-scaling%3Dfixed%26page-id%3D533%253A308&hide-ui=1"
        allowFullScreen
      />
    </div> */}

      <h2 class="mt-20 max-w-5xl px-4 bg-white mb-4 font-sans text-3xl text-center font-semibold leading-none tracking-tight text-gray-900 sm:text-[60px] md:mx-auto">
        {t("spprototypetitle")}
      </h2>
      <p className='text-gray-600 text-center mt-3 text-lg max-w-2xl mx-auto'>{t("spprototypedescription")}</p>

      <iframe
        src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2Fm2SxDJKgzkr9K5UCoTn4Ya%2FPrototype-system-Invu%3Fnode-id%3D533-309%26node-type%3DCANVAS%26t%3DtOnevvtQivOeGHGX-1%26content-scaling%3Dfixed%26page-id%3D533%253A308&hide-ui=1"
        // src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2Fm2SxDJKgzkr9K5UCoTn4Ya%2FPrototype-system-Invu%3Fnode-id%3D533-309%26node-type%3DCANVAS%26t%3DtOnevvtQivOeGHGX-1%26scaling%3Dscale-down%26content-scaling%3Dfixed%26page-id%3D533%253A308&hide-ui=1"
        allowFullScreen
        style={{
          width: '100%',
          height: height,
          // margin: isMobile ? '0px' : '0', 
          // padding: isMobile ? '12px' : '0', 
          border: 'none',
        }}
      />

    {/* <div className="px-4 py-16 mx-auto text-left sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
    <div className="max-w-xl mb-10 sm:text-left lg:max-w-5xl md:mb-12">
        <h2 className="max-w-5xl mb-6 font-sans text-3xl font-semibold leading-none tracking-tight text-gray-900 sm:text-[40px] md:mx-auto">
        {t("supportsection2title")}  <span className="text-blue-600">{t("supportsection2titlespan")}</span>
        </h2>
      </div>

      <div className="grid gap-8 lg:grid-cols-3 md:grid-cols-2 sm:mx-auto lg:max-w-full">
        
        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
          <img
            src="https://images.pexels.com/photos/7731402/pexels-photo-7731402.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            className="object-cover w-full h-64"
            alt=""
          />
          <div className="p-5 border border-t-0">
            <a
              aria-label="Category"
              className="inline-block mb-4 mt-4 text-2xl font-bold leading-5 transition-colors duration-200 hover:text-deep-purple-accent-700"
            >
              {t("supportsection2card1title")}
            </a>
            <p className="mb-2 text-gray-700">
              {t("supportsection2card1description")}
            </p>
          </div>
        </div>

        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
          <img
            src="https://images.pexels.com/photos/6694924/pexels-photo-6694924.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            className="object-cover w-full h-64"
            alt=""
          />
           <div className="p-5 border border-t-0">
            <a
              aria-label="Category"
              className="inline-block mb-4 mt-4 text-2xl font-bold leading-5 transition-colors duration-200 hover:text-deep-purple-accent-700"
            >
              {t("supportsection2card2title")}
            </a>
            <p className="mb-2 text-gray-700">
              {t("supportsection2card2description")}
            </p>
          </div>
        </div>

        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded ">
          <img
            src="https://images.pexels.com/photos/6812431/pexels-photo-6812431.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            className="object-cover w-full h-64"
            alt=""
          />
          <div className="p-5 border border-t-0">
            <a
              aria-label="Category"
              className="inline-block mb-4 mt-4 text-2xl font-bold leading-5 transition-colors duration-200 hover:text-deep-purple-accent-700"
            >
              {t("supportsection2card3title")}
            </a>
            <p className="mb-2 text-gray-700">
              {t("supportsection2card3description")}
            </p>
          </div>
        </div>
        
      </div>
    </div> */}

      <div className="mt-8 px-4 py-0 mx-auto text-left sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 ">
        <div className="max-w-xl mb-4 sm:text-left lg:max-w-5xl md:mb-12">
          <h2 className="max-w-5xl mb-6 font-sans text-3xl font-semibold leading-none tracking-tight text-gray-900 sm:text-[40px] md:mx-auto">
          {t("sptabstitle")}
          </h2>
          <p className='text-gray-500 text-xl'>
         {t("sptabsdescription")}
          </p>
        </div>
      </div>
      <Main_tabs_demo/>


      <div className="max-w-screen-xl px-4 mt-20 mx-auto sm:px-6 lg:px-8 lg:mt-72">
      <div className="flex flex-col md:flex-row items-center lg:gap-5 rounded-2xl bg-gray-100 shadow-md overflow-visible relative">
        <div className="md:w-2/5 w-full overflow-visible relative order-2 md:order-1">
          <img
            src="https://img.invupos.com/webinvu/CTA/CTA-WOMEN-SUPPORT.png"
            alt="CTA"
            className="object-cover h-full w-full xl:-mt-36 mt-4" 
          />
        </div>
        <div className="md:w-1/2 w-full p-6 order-1 md:order-2">
          <h2 class="mb-4 font-sans text-4xl lg:mt-0 mt-8 font-bold tracking-tight text-gray-900 xl:text-[45px] md:text-[40px] sm:leading-none"> {t("spctatitle")}</h2>
          <p class="xl:text-lg lg:text-md md:text-base leading-8 text-gray-700 mb-3">{t("spctadescription")}</p>
          <div class="flex items-center">
            <a
              href="https://soporte.invupos.com/es-LA/support/solutions"
              target="_blank"
              class="group flex mt-2 items-center justify-between gap-3 rounded-full border border-indigo-600 bg-indigo-600 px-5 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring"
            >
              <span
                class="font-medium text-white transition-colors group-hover:text-indigo-600 group-active:text-indigo-500"
              >
                {t("spctabutton")}
              </span>
              <span
                class="shrink-0 rounded-full border border-current bg-white p-2 text-indigo-600 group-active:text-indigo-500"
              >
                <svg
                  class="h-5 w-5 rtl:rotate-180"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </span>
            </a>
        </div>
        </div>
      </div>
    </div>


      <section>
        <div class="max-w-screen-xl px-4 mt-20 mx-auto sm:px-6 lg:px-8">
       <div className="flex bg-gradient-to-r from-[#eff1fe] to-[#cad0fb] flex-col justify-between px-4 mx-auto lg:flex-row md:px-12 lg:max-w-scr sm:px-6 lg:px-12een-xl rounded-2xl">
      <div className="pt-16 lg:mb-16 mb-10 lg:mb-0 lg:pt-20 lg:max-w-lg lg:pr-5">
        <div className="max-w-xl mb-6">
          <h2 className="max-w-lg mb-6 font-sans text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl sm:leading-none">
          {t("posctacontacttitle")}
          </h2>
          <p className="text-base text-gray-700 md:text-lg">
          {t("posctacontactposdescription")}
          </p>
        </div>
        <div className="flex items-center">
        <button
        onClick={() => {
          setIsOpen(true);
        }}
            class="group flex mt-2 items-center justify-between gap-3 rounded-full border border-indigo-600 bg-indigo-600 px-5 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring"
          >
            <span
              class="font-medium text-white transition-colors group-hover:text-indigo-600 group-active:text-indigo-500"
            >
              {t("posctacontactbutton")}
            </span>

            <span
              class="shrink-0 rounded-full border border-current bg-white p-2 text-indigo-600 group-active:text-indigo-500"
            >
              <svg
                class="h-5 w-5 rtl:rotate-180"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            </span>
          </button>
        </div>
      </div>
      <div className="items-center flex content-center lg:mb-0 mb-12">
        <img
          src="https://img.invupos.com/webinvu/Point%20of%20sale%20module/mockupterminalnew.png"
          className="w-[450px] xl:mr-20 rounded-3xl"
          alt=""
        />
      </div>
    </div>
    </div>
    </section>      

 


<div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="max-w-xl mb-10  lg:max-w-2xl md:mb-12">
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl">
        {t("supportsection4title")}
        </h2>
        <div class="mt-2 ">
            <button 
            onClick={() => setIsOpen(true)}
            class="py-4 px-5 inline-flex justify-center text-lg items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent bg-indigo-600 text-white hover:bg-indigo-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="#">
              {t("supportsection4button")}
            </button>
          </div>
      </div>
      <div className="grid gap-5 mb-8 md:grid-cols-2 lg:grid-cols-4">
        <div className="p-5 duration-300 transform bg-white border rounded-xl shadow-sm hover:-translate-y-2">
          <div className="mb-6">
          <img
            src="https://images.pexels.com/photos/1229180/pexels-photo-1229180.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            className="object-cover w-full h-52 rounded-xl"
            alt=""
          />
          </div>
          <h6 className="mb-2 font-semibold leading-5 text-lg">{t("supportsection4card1title")}</h6>
          <p className="text-md text-gray-900">
          {t("supportsection4card1description")}
          </p>
        </div>
        <div className="p-5 duration-300 transform bg-white border rounded-xl shadow-sm hover:-translate-y-2">
          <div className="mb-6">
          <img
            src="https://images.pexels.com/photos/6406691/pexels-photo-6406691.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            className="object-cover w-full h-52 rounded-xl"
            alt=""
          />
          </div>
          <h6 className="mb-2 font-semibold leading-5 text-lg">{t("supportsection4card2title")}</h6>
          <p className="text-md text-gray-900">
          {t("supportsection4card2description")}
          </p>
        </div>
        <div className="p-5 duration-300 transform bg-white border rounded-xl shadow-sm hover:-translate-y-2">
          <div className="mb-6">
          <img
            src="https://images.pexels.com/photos/3769747/pexels-photo-3769747.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            className="object-cover w-full h-52 rounded-xl"
            alt=""
          />
          </div>
          <h6 className="mb-2 font-semibold leading-5 text-lg">{t("supportsection4card3title")}</h6>
          <p className="text-md text-gray-900">
          {t("supportsection4card3description")}
          </p>
        </div>
        <div className="p-5 duration-300 transform bg-white border rounded-xl shadow-sm hover:-translate-y-2">
          <div className="mb-6">
          <img
            src="https://images.pexels.com/photos/2696064/pexels-photo-2696064.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            className="object-cover w-full h-52 rounded-xl"
            alt=""
          />
          </div>
          <h6 className="mb-2 font-semibold leading-5 text-lg">{t("supportsection4card4title")}</h6>
          <p className="text-md text-gray-900">
          {t("supportsection4card4description")}
          </p>
        </div>

        
      </div>
     
    </div>
    
    <div className="-mt-20">
    <CTA_home/>
    </div>

      <FAQ_model_one />
      {/* <Help_CTA /> */}
    </>
  );
};

export default Help_center;
